import { ShareType } from "@capchapdev/rell-api";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import type { useEntitiesQuery } from "../../../api/rest/entities";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type {
  TDraftShareBlock,
  TDraftShareType,
} from "../../../types/models/draft";
import { formatNumber } from "../../../utils/format";
import { calcSumWithinRange } from "../../../utils/shares";
import { Button } from "../../design-system/Button";
import { Description } from "../../design-system/Description";
import { DistributionProgress } from "../../design-system/DistributionProgress";
import { EntityItem } from "../../design-system/EntityItem";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  TrashIcon,
} from "../../design-system/icons";
import { TableV2 } from "../../design-system/TableV2";
import { NoData } from "../../NoData";
import { AddShareBlock } from "../Add";
import { EditShareBlock } from "../Edit";

type ShareBlocksListProps = {
  value: TDraftShareBlock[];
  onChange: (value: TDraftShareBlock[]) => void;
  entitiesQuery: ReturnType<typeof useEntitiesQuery>;
  currentCompany: CompanyInvolvement | CompanyInformation;
  shareTypes: TDraftShareType[];
  diff?: number;
  offset?: number;
  disableNewEntity?: boolean;
};

const swapItems = (
  value: TDraftShareBlock[],
  prevIndex: number,
  currentIndex: number
) => {
  const prevBlock = value[prevIndex];
  const block = value[currentIndex];
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const blockSize = calcSumWithinRange(block);

  return value.map((b, bIndex) =>
    bIndex === prevIndex
      ? {
          ...block,
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          start: prevBlock.start,
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          end: prevBlock.start + blockSize - 1,
        }
      : bIndex === currentIndex
      ? {
          ...prevBlock,
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          start: prevBlock.start + blockSize,
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          end: block.end,
        }
      : b
  );
};

type ShareBlockRowControlsProps = {
  index: number;
  block: TDraftShareBlock;
  items: TDraftShareBlock[];
  onChange: (value: TDraftShareBlock[]) => void;
  entitiesQuery: ReturnType<typeof useEntitiesQuery>;
  currentCompany: CompanyInvolvement | CompanyInformation;
  shareTypes: ShareType[];
  disableNewEntity?: boolean;
};

const ShareBlockRowControls = ({
  index,
  block,
  items,
  onChange,
  entitiesQuery,
  currentCompany,
  shareTypes,
  disableNewEntity,
}: ShareBlockRowControlsProps) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const blockSize = calcSumWithinRange(block);

  return (
    <div className="tw-flex tw-items-center tw-justify-start tw-gap-1 md:tw-order-last md:tw-justify-end">
      {index !== items.length - 1 && (
        <Button
          variant={isTabletOrMobileDevice ? "outline" : "clean"}
          size="md"
          onClick={(e) => {
            e.stopPropagation();
            // @ts-expect-error TS(2345) FIXME: Argument of type '{ start: number; end: number; ho... Remove this comment to see the full error message
            onChange(swapItems(items, index, index + 1));
          }}
        >
          <ArrowDownIcon className="tw-h-6 tw-w-6" />
        </Button>
      )}
      {index !== 0 && (
        <Button
          variant={isTabletOrMobileDevice ? "outline" : "clean"}
          size="md"
          onClick={(e) => {
            e.stopPropagation();
            // @ts-expect-error TS(2345) FIXME: Argument of type '{ start: number; end: number; ho... Remove this comment to see the full error message
            onChange(swapItems(items, index - 1, index));
          }}
        >
          <ArrowUpIcon className="tw-h-6 tw-w-6" />
        </Button>
      )}
      <EditShareBlock
        disableNewEntity={disableNewEntity}
        currentCompany={currentCompany}
        entitiesQuery={entitiesQuery}
        shareTypes={shareTypes}
        initialValue={{
          numberOfShares: blockSize,
          holderId: block.holderId,
          type: block.type,
        }}
        onSuccess={(newValue) => {
          const diff = (newValue.numberOfShares || 0) - blockSize;
          onChange(
            items.map((b) =>
              b.start < block.start
                ? b
                : block.start === b.start
                ? {
                    start: block.start,
                    end: block.end + diff,
                    type: newValue.type || "",
                    holderId: newValue.holderId || "",
                  }
                : { ...b, start: b.start + diff, end: b.end + diff }
            )
          );
        }}
      />
      <Button
        variant={isTabletOrMobileDevice ? "outline" : "clean"}
        size="md"
        onClick={(e) => {
          e.stopPropagation();
          const withoutCurrentBlock = items.filter(
            (b) => b.start !== block.start
          );

          onChange(
            withoutCurrentBlock.map((b) =>
              b.start < block.start
                ? b
                : {
                    ...b,
                    start: b.start - blockSize,
                    end: b.end - blockSize,
                  }
            )
          );
        }}
      >
        <TrashIcon className="tw-h-6 tw-w-6" />
      </Button>
    </div>
  );
};

const ShareBlocksList = ({
  value,
  onChange,
  entitiesQuery,
  currentCompany,
  shareTypes,
  diff,
  offset = 0,
  disableNewEntity,
}: ShareBlocksListProps) => {
  const i18n = useTranslation();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>([]);
  const entitiesData = entitiesQuery.data || [];
  const entitiesMap = Object.fromEntries(entitiesData.map((e) => [e.id, e]));

  return (
    <div className="tw-space-y-4 md:tw-rounded md:tw-border md:tw-p-4">
      <div className="tw-flex tw-justify-between">
        <h2 className="tw-text-lg tw-font-medium">
          {i18n.t("label.shareBlocks")}
        </h2>
        {diff !== undefined && <DistributionProgress diff={diff} />}
      </div>
      <div>
        {value.length === 0 ? (
          <NoData />
        ) : (
          <TableV2
            columns={[
              {
                name: "range",
                title: i18n.t("label.shareBlock"),
                sortable: false,
                key: true,
              },
              {
                name: "entity",
                title: i18n.t("label.shareholder"),
                sortable: false,
                key: true,
              },
              {
                name: "controls",
                title: "",
                sortable: false,
                key: !isTabletOrMobileDevice,
              },
            ]}
            data={value.map((block, i) => {
              const entity = entitiesMap[block.holderId];

              return {
                key: block.start.toString(),
                range: (
                  <Description
                    title={`${formatNumber(block.start)} - ${formatNumber(
                      block.end
                    )}`}
                    description={`${formatNumber(calcSumWithinRange(block))} ${
                      block.type
                    }`}
                  />
                ),
                entity: entity ? (
                  <EntityItem
                    value={entity}
                    displayIcon={false}
                    className={
                      isTabletOrMobileDevice ? "tw-text-right" : undefined
                    }
                    flagPosition={isTabletOrMobileDevice ? "left" : "right"}
                  />
                ) : undefined,
                controls: (
                  <ShareBlockRowControls
                    index={i}
                    block={block}
                    items={value}
                    onChange={onChange}
                    entitiesQuery={entitiesQuery}
                    currentCompany={currentCompany}
                    shareTypes={shareTypes}
                    disableNewEntity={disableNewEntity}
                  />
                ),
              };
            })}
            expandedRows={expandedRows}
            setExpandedRows={setExpandedRows}
          />
        )}
      </div>
      <AddShareBlock
        currentCompany={currentCompany}
        entitiesQuery={entitiesQuery}
        shareTypes={shareTypes}
        onSuccess={(newValue) => {
          const previousEnd = value.at(-1)?.end || offset;
          onChange([
            ...value,
            {
              start: previousEnd + 1,
              end: previousEnd + (newValue.numberOfShares || 0),
              type: newValue.type || "",
              holderId: newValue.holderId || "",
            },
          ]);
        }}
        disableNewEntity={disableNewEntity}
        variant="outline"
        color="secondary"
        className="tw-w-full"
      />
    </div>
  );
};

export { ShareBlockRowControls, ShareBlocksList };
