import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useSearchParams } from "react-router-dom";

import { useShareholdersQuery } from "../../api/blockchain/company";
import { votingRegisterDocumentDownload } from "../../api/rest/company";
import { useEntitiesQuery } from "../../api/rest/entities";
import { Checkbox } from "../../components/design-system/Checkbox";
import { Description } from "../../components/design-system/Description";
import { EntityItem } from "../../components/design-system/EntityItem";
import { SearchIcon } from "../../components/design-system/icons";
import { Input } from "../../components/design-system/Input";
import { Loading } from "../../components/design-system/Loading";
import { PageHeader } from "../../components/design-system/PageHeader/PageHeader";
import { Select } from "../../components/design-system/Select";
import { TableV2 } from "../../components/design-system/TableV2";
import { Toggle } from "../../components/design-system/Toggle";
import { TooltipV2 } from "../../components/design-system/Tooltip/TooltipV2";
import {
  ExportMenuVotingRegister,
  FormData,
} from "../../components/ExportMenuVotingRegister";
import { PageWrapper } from "../../components/PageWrapper";
import { SelectVersion } from "../../components/SelectVersion";
import { useCurrentCompany } from "../../context/account";
import { Entity } from "../../types/models/entities";
import { Shareholder } from "../../types/models/shares";
import { downloadBlob } from "../../utils/download";
import {
  formatNumber,
  formatPercentage,
  formatRefId,
} from "../../utils/format";
import * as monitoring from "../../utils/monitoring";
import { exportToExcel } from "../../utils/xlsx";
import { getInitialVersion } from "../companies/[companyId]/shares";
import { LedgerVersionDetails, useLedgerVersions } from "./useLedgerVersions";

const VotingRegister = () => {
  const i18n = useTranslation();
  type RepresentativeType = "representative" | "power";
  const representativeTypes: { label: string; value: RepresentativeType }[] = [
    {
      label: i18n.t("votingRegister.proxy.type.representative"),
      value: "representative",
    },
    { label: i18n.t("votingRegister.proxy.type.power"), value: "power" },
  ];

  const currentCompany = useCurrentCompany();
  const [searchParams, setSearchParams] = useSearchParams();
  const ledgerVersions = useLedgerVersions(currentCompany?.orgNumber);
  const versionStringInQuery = searchParams.get("version");
  const initialLedgerVersion = getInitialVersion(
    ledgerVersions,
    versionStringInQuery
  );
  const [selectedVersion, setSelectedVersion] = useState<
    LedgerVersionDetails | undefined
  >(initialLedgerVersion);
  useEffect(() => {
    setSelectedVersion(initialLedgerVersion);
  }, [initialLedgerVersion]);

  const [sortBy, setSortBy] = useState("votes-desc");
  const [expanded, setExpanded] = useState<Record<number, boolean>>({});
  const [search, setSearch] = useState("");
  const [selectedShareholders, setSelectedShareholders] = useState<string[]>(
    []
  );
  const [selectedRepresentatives, setSelectedRepresentatives] = useState<
    Record<string, { name: string; type: RepresentativeType }>
  >({});
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const shareHoldersQuery = useShareholdersQuery(
    currentCompany?.orgNumber,
    selectedVersion?.formatedValue
  );
  const shareholders = shareHoldersQuery.data?.shareholders || [];
  const totalVotes = shareHoldersQuery.data?.totalVotes || 0;
  const totalShares = shareHoldersQuery.data?.totalShares || 0;
  const entitiesQuery = useEntitiesQuery(currentCompany?.orgNumber);
  const entitiesData = entitiesQuery.data || [];
  const entitiesMap: Record<string, Entity> = Object.fromEntries(
    entitiesData.map((e) => [e.id, e])
  );
  useEffect(() => {
    if (selectedShareholders.length === 0) {
      setShowSelectedOnly(false);
    }
  }, [selectedShareholders]);

  const companiesMissingProxy = entitiesData.filter(
    (e) =>
      selectedShareholders.includes(e.id) &&
      e.type === "Company" &&
      !selectedRepresentatives[e.id]
  );

  if (!currentCompany || !initialLedgerVersion) {
    return <Loading />;
  }

  const onVersionChange = (newVersion?: LedgerVersionDetails) => {
    if (newVersion) {
      setSearchParams({
        ...searchParams,
        version: newVersion.formatedValue ?? "",
      });
    }
    setSelectedVersion(newVersion);
  };

  const sortHolders = (holders: Shareholder[]) => {
    const [sort, sortOrder] = sortBy.split("-");
    const isAscending = sortOrder === "asc";

    if (sort === "votes") {
      return holders.sort((a, b) =>
        isAscending ? a.totalVotes - b.totalVotes : b.totalVotes - a.totalVotes
      );
    }

    return holders.sort((a, b) =>
      isAscending
        ? (entitiesMap[a.holder.id]?.name || "").localeCompare(
            entitiesMap[b.holder.id]?.name || ""
          )
        : (entitiesMap[b.holder.id]?.name || "").localeCompare(
            entitiesMap[a.holder.id]?.name || ""
          )
    );
  };

  const downloadVotingXlsx = (form: FormData) => {
    const lng = form.language;

    const data = selectedShareholders.map((id) => {
      const representative = selectedRepresentatives[id];
      const entity = entitiesMap[id]!;
      const shareholder = sortedShareholders.find((s) => s.holder.id === id);

      return {
        [i18n.t("label.shareholder", { lng })]: entity.name,
        [i18n.t("label.id", { lng })]: formatRefId({
          refId: entity.refId,
          countryCode: entity.countryCode,
          birthDate: entity.birthDate,
          type: entity.type,
        }),
        ...(representative && {
          [i18n.t("votingRegister.column.proxy", { lng })]:
            representative.type === "power" || entity.type === "Private"
              ? `${representative.name} ${i18n.t(
                  "votingRegister.proxy.type.power.short",
                  { lng }
                )}`
              : representative.name,
        }),
        [i18n.t("label.shares", { lng })]: shareholder?.totalShares || 0,
        [`% ${i18n.t("label.shares", { lng })}`]: formatPercentage(
          (shareholder?.totalShares || 0) / totalShares
        ),
        [i18n.t("label.votes", { lng })]: shareholder?.totalVotes || 0,
        [`% ${i18n.t("label.votes", { lng })}`]: formatPercentage(
          (shareholder?.totalVotes || 0) / totalVotes
        ),
      };
    });

    exportToExcel(
      data,
      [i18n.t("label.shares", { lng }), i18n.t("label.votes", { lng })],
      `${currentCompany.name} - ${currentCompany.orgNumber} - ${i18n.t(
        "label.votingRegister",
        { lng }
      )} - ${i18n.t("label.downloaded", {
        lng,
        date: form.date,
      })}`,
      `${i18n.t("label.votingRegister", { lng })} ${form.date}`,
      false
    );
  };

  const downloadVotingPdf = async (form: FormData) => {
    if (companiesMissingProxy.length > 0) {
      return;
    }
    const data = selectedShareholders.reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: selectedRepresentatives[curr] || {},
      }),
      {}
    );
    const response = await votingRegisterDocumentDownload(
      currentCompany.orgNumber,
      data,
      form.date,
      form.language
    );
    if (response.status === 200) {
      const blob = await response.blob();
      downloadBlob(
        blob,
        `voting_${currentCompany.name}_${currentCompany.orgNumber}_${form.date}.pdf`
      );
    } else {
      console.error(response);
      monitoring.captureException(
        new Error("Error downloading voting document"),
        {
          extra: {
            status: response.status,
            text: response.statusText,
            company: currentCompany.orgNumber,
          },
        }
      );
    }
  };

  if (shareHoldersQuery.isLoading || entitiesQuery.isLoading) {
    return <Loading />;
  }

  const shareholdersToShow = showSelectedOnly
    ? shareholders.filter((s) => selectedShareholders.includes(s.holder.id))
    : shareholders;
  const filteredShareholders = search
    ? shareholdersToShow.filter((s) => {
        const entity = entitiesMap[s.holder.id];
        return (
          entity?.name.toLowerCase().includes(search.toLowerCase()) ||
          entity?.refId.toLowerCase().includes(search.toLowerCase())
        );
      })
    : shareholdersToShow;
  const sortedShareholders = sortHolders(filteredShareholders);
  const rows = sortedShareholders
    .map((s) => {
      const entity = entitiesMap[s.holder.id];
      if (!entity) {
        return null;
      }
      return {
        key: s.holder.id,
        select: (
          <Checkbox
            onClick={(e) => {
              e.stopPropagation();
              setSelectedShareholders((prev) =>
                prev.includes(s.holder.id)
                  ? prev.filter((i) => i !== s.holder.id)
                  : [...prev, s.holder.id]
              );
            }}
            checked={selectedShareholders.includes(s.holder.id)}
          />
        ),
        shareholder: <EntityItem value={entity} displayIcon={false} />,
        representative: (
          <div className="tw-flex tw-flex-col tw-gap-2 max-md:tw-p-2 md:tw-flex-row">
            <Input
              value={selectedRepresentatives[s.holder.id]?.name}
              onChange={(e) =>
                setSelectedRepresentatives((prev) =>
                  e.target.value === ""
                    ? Object.fromEntries(
                        Object.entries(prev).filter(
                          ([id, _]) => id !== s.holder.id
                        )
                      )
                    : {
                        ...prev,
                        [s.holder.id]: {
                          type:
                            prev[s.holder.id]?.type ||
                            (s.holder.type === "Private"
                              ? "power"
                              : "representative"),
                          name: e.target.value,
                        },
                      }
                )
              }
              placeholder={i18n.t(
                entity.type === "Company"
                  ? "votingRegister.proxy.placeholder.company"
                  : "votingRegister.proxy.placeholder.person"
              )}
            />
            {s.holder.type === "Company" && (
              <Select
                name="representativeType"
                options={representativeTypes}
                value={
                  representativeTypes.find(
                    (o) =>
                      selectedRepresentatives[s.holder.id]?.type === o.value
                  ) || representativeTypes[0]
                }
                onChange={(l) =>
                  setSelectedRepresentatives((prev) => ({
                    ...prev,
                    [s.holder.id]: {
                      type: l!.value,
                      name: prev[s.holder.id]?.name || "",
                    },
                  }))
                }
              />
            )}
          </div>
        ),
        votes: (
          <Description
            title={formatPercentage(s.totalVotes / totalVotes)}
            description={formatNumber(s.totalVotes)}
            className="md:tw-text-right"
            descriptionClassName="md:tw-text-right"
          />
        ),
      };
    })
    .filter((i) => i !== null);
  const selectedVotes = selectedShareholders.reduce(
    (prev, curr) =>
      prev + (shareholders.find((s) => s.holder.id === curr)?.totalVotes || 0),
    0
  );

  return (
    <PageWrapper>
      <PageHeader
        title={i18n.t("label.votingRegister")}
        description={i18n.t("votingRegister.description")}
        actions={[
          <TooltipV2
            key="export"
            content={
              selectedShareholders.length === 0
                ? i18n.t("votingRegister.required")
                : companiesMissingProxy.length > 0 &&
                  i18n.t("votingRegister.proxy.required")
            }
            className="max-md:tw-w-full"
          >
            <ExportMenuVotingRegister
              downloadPdf={downloadVotingPdf}
              downloadXlsx={downloadVotingXlsx}
              disabled={
                selectedShareholders.length === 0 ||
                companiesMissingProxy.length > 0
              }
            />
          </TooltipV2>,
        ]}
      />
      <div className="tw-mt-4 tw-flex tw-flex-col tw-gap-4">
        <div className="tw-flex tw-flex-col tw-justify-between tw-gap-4 md:tw-flex-row">
          <div className="tw-flex tw-flex-col tw-gap-4 md:tw-flex-row">
            <SelectVersion
              selectedVersion={selectedVersion}
              availableVersions={ledgerVersions}
              onChange={onVersionChange}
            />
            <Input
              className="tw-h-12 tw-w-52 max-md:tw-w-full"
              placeholder={i18n.t("label.search")}
              prefix={<SearchIcon />}
              type="search"
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
          </div>
          {selectedShareholders.length > 0 && (
            <Toggle
              label={i18n.t("votingRegister.toggle")}
              isActive={showSelectedOnly}
              onClick={() => setShowSelectedOnly(!showSelectedOnly)}
              className="tw-w-fit"
            />
          )}
        </div>
        <TableV2
          columns={[
            {
              name: "select",
              title: (
                <Checkbox
                  checked={selectedShareholders.length >= shareholders.length}
                  onClick={() => {
                    if (selectedShareholders.length < shareholders.length) {
                      setSelectedShareholders(
                        shareholders.map((s) => s.holder.id)
                      );
                    } else {
                      setSelectedShareholders([]);
                    }
                  }}
                  className="max-md:tw-hidden"
                />
              ),
              key: true,
              sortable: false,
              compact: true,
            },
            {
              name: "shareholder",
              title: i18n.t("votingRegister.column.shareholder"),
              key: true,
            },
            {
              name: "representative",
              title: i18n.t("votingRegister.column.proxy"),
              key: !isTabletOrMobileDevice,
              sortable: false,
            },
            {
              name: "votes",
              title: i18n.t("votingRegister.column.votes"),
              key: !isTabletOrMobileDevice,
              className: "tw-flex tw-justify-end",
            },
          ]}
          data={
            isTabletOrMobileDevice
              ? rows
              : [
                  ...rows,
                  {
                    key: "total",
                    shareholder:
                      selectedShareholders.length > 0 ? (
                        <p className="tw-text-sm">
                          {i18n.t("votingRegister.total.shareholders", {
                            total: selectedShareholders.length,
                          })}
                        </p>
                      ) : undefined,
                    votes: (
                      <Description
                        title={formatPercentage(selectedVotes / totalVotes)}
                        description={i18n.t("votingRegister.total.votes")}
                        className="md:tw-text-right"
                        descriptionClassName="md:tw-text-right"
                      />
                    ),
                  },
                ]
          }
          sortBy={sortBy}
          setSortBy={setSortBy}
          expandedRows={expanded}
          setExpandedRows={setExpanded}
        />
      </div>
    </PageWrapper>
  );
};

export default VotingRegister;
