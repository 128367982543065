import {
  Control,
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormRegister,
} from "react-hook-form";

import { FormError } from "../../../components/design-system/FormGroup";
import { ShareBlock } from "../../../components/design-system/SelectRange";
import {
  ShareholderBlocks,
  SharesTransferForm,
  Transfer,
} from "../../../types/models/shares";
import { shareOwnershipByType } from "../../../utils/shares";
import { TransferMethodSelector } from "./TransferMethod";
import { TransferNumber } from "./TransferNumber";
import { TransferRange } from "./TransferRange";

type TransferErrors =
  | Merge<
      FieldError,
      FieldErrorsImpl<{
        [x: string]: {
          transfers: {
            shareRanges: {
              block: {
                start: number;
                end: number;
              };
              start: number;
              end: number;
            }[];
            shareType: string;
            sharesAmount: number;
          }[];
          type: NonNullable<"range" | "basic" | undefined>;
        };
      }>
    >
  | undefined;

type TransferFormProps = {
  id: string;
  value: Transfer[];
  method?: "range" | "basic";
  onChange: (value: Transfer[]) => void;
  sender?: string;
  shareHolders: ShareholderBlocks[];
  shareBlocks: ShareBlock[];
  register: UseFormRegister<SharesTransferForm>;
  control: Control<SharesTransferForm>;
  errors?: TransferErrors;
};

const TransferForm = ({
  id,
  value,
  method,
  onChange,
  sender,
  shareHolders,
  shareBlocks,
  register,
  control,
  errors,
}: TransferFormProps) => {
  const selectedHolder = shareHolders.find((holder) => holder.id === sender);
  const shareTypes = shareOwnershipByType(selectedHolder?.blocks || []);

  return (
    <div className="tw-space-y-6">
      <Controller
        control={control}
        name={`transfers.${id}.type`}
        render={({ field: { value: type, onChange: change }, fieldState }) => (
          <div className="tw-space-y-2">
            <TransferMethodSelector
              value={type}
              onChange={(v) => {
                change(v);
                onChange([]);
              }}
            />
            <FormError>{fieldState.error?.message}</FormError>
          </div>
        )}
      />

      {method === "basic" ? (
        <TransferNumber
          id={id}
          allValues={value}
          shareTypes={shareTypes}
          sender={sender}
          control={control}
          register={register}
          errors={errors}
        />
      ) : method === "range" ? (
        <TransferRange
          id={id}
          allValues={value}
          shareTypes={shareTypes}
          shareBlocks={shareBlocks}
          sender={sender}
          control={control}
          register={register}
          errors={errors}
        />
      ) : null}
      {errors && errors[id] && (
        <FormError>{errors[id].transfers?.root?.message}</FormError>
      )}
    </div>
  );
};

export { TransferForm };
export type { TransferErrors };
