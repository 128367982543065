import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  useLedgerQuery,
  useShareblocksQuery,
  useShareholderBlocksQuery,
  useShareTypesQuery,
} from "../../../api/blockchain/company";
import { useEntitiesQuery } from "../../../api/rest/entities";
import {
  useReclassifySharesClass,
  useReclassifySharesRange,
} from "../../../api/rest/events";
import { EventFormProps } from "../../../components/AddEvents/EventsWizard.utils";
import { Description } from "../../../components/design-system/Description";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from "../../../components/design-system/icons";
import { Input } from "../../../components/design-system/Input";
import { RadioGroup } from "../../../components/design-system/RadioGroup";
import { Select } from "../../../components/design-system/Select";
import { SelectEntity } from "../../../components/design-system/SelectEntity";
import {
  matchEntityToBlocks,
  SelectRange,
  ShareRangeError,
} from "../../../components/design-system/SelectRange";
import { SelectClauses } from "../../../components/ShareTypes/SelectRestrictiveConditions";
import useLatestVersion from "../../../hooks/useLatestVersion";
import type {
  Condition,
  Shareblock,
  ShareRange,
} from "../../../types/models/shares";
import { dateToIsoString } from "../../../utils/date";
import {
  hasOverlappingRanges,
  sumRanges,
  validRanges,
} from "../../../utils/shares";
import { getSelectedBlocksByTypeFromShareholders } from "../CompanyShares.utils";
import { ShareTypeAutofill } from "../IssueShares/Components/Dialog/ShareTypeAutofill";

type ActionType = "class" | "range" | "shareholders";
type TargetShareClassType = "existing" | "new";

type ActionOption = { value: ActionType; title: string };

type TargetShareClassTypeOption = {
  value: TargetShareClassType;
  title: string;
};

type FormProps = {
  date: string;
  type: ActionType;
  fromShareClass: string;
  fromShareRanges: ShareRange[];
  fromShareholders: string[];
  targetShareClassType: TargetShareClassType;
  targetExistingShareClass: string;
  newShareClassName: string;
  newShareClassVoteValue: number;
  newShareCondition: Condition;
};

const ShareClass: React.FunctionComponent<{
  name: string;
  shareblocks: Shareblock[];
}> = ({ name, shareblocks }) => {
  const [shares, setShares] = useState(false);

  return (
    <div key={name}>
      <div className="tw-flex tw-justify-between">
        <h6 className="tw-p-0">{name}</h6>

        {shares ? (
          <ChevronUpIcon
            onClick={() => {
              setShares((prev) => !prev);
            }}
          />
        ) : (
          <ChevronDownIcon
            onClick={() => {
              setShares((prev) => !prev);
            }}
          />
        )}
      </div>

      <div className="tw-flex tw-flex-wrap">
        {shares &&
          shareblocks.map((item) => (
            <div key={item.start} className="tw-pt-2">
              <span className="tw-rounded-2xl tw-border-2 tw-border-solid tw-p-1 tw-text-xs">{`${item.start} - ${item.end}`}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

const reclassificationFormId = "reclassification-form";

const Reclassification = ({
  currentCompany,
  onSuccess,
  setFormData,
}: EventFormProps) => {
  const i18n = useTranslation();

  const {
    handleSubmit,
    control,
    watch,
    register,
    formState: { errors },
    setError,
    setValue,
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues: {
      fromShareRanges: [],
      newShareCondition: {
        consent: false,
        preemption: false,
        redemption: false,
        conversion: false,
        offerOfFirstRefusal: false,
      },
    },
  });

  const formValues = watch();
  const lastEventDate = useLatestVersion();
  const shareTypesQuery = useShareTypesQuery(currentCompany.orgNumber, "");

  const shareTypes = shareTypesQuery.data || [];
  const minVote = Math.min(...shareTypes.map((type) => type.voteValue));
  const maxVote = Math.max(...shareTypes.map((type) => type.voteValue));

  const ledgerQuery = useLedgerQuery(currentCompany.orgNumber, "");
  const shareblocksArray =
    useShareblocksQuery(currentCompany.orgNumber, "").data ?? [];

  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const entities = entitiesQuery.data || [];
  const entitiesMap = Object.fromEntries(entities.map((e) => [e.id, e]));

  const shareBlocksQuery = useShareholderBlocksQuery(currentCompany.orgNumber);
  const shareBlocks = shareBlocksQuery.data || [];
  const shareholders = entities.filter((e) =>
    shareBlocks.some((b) => b.id === e.id)
  );
  const blocksWithEntity = matchEntityToBlocks(shareblocksArray, entitiesMap);

  const shareblocksByName = Object.entries(
    shareblocksArray.reduce<Record<string, Shareblock[]>>((acc, cur) => {
      if (cur.type in acc) {
        acc[cur.type]?.push(cur);
      } else {
        acc[cur.type] = [];
        acc[cur.type]?.push(cur);
      }

      return acc;
    }, {})
  ).map((item): [string, Shareblock[]] => {
    const type = item[0];
    const shareArray = item[1];
    const availableShares = shareArray.filter((block) => !block.cancelled);

    return [type, availableShares];
  });

  const reclassifySharesClassMutation = useReclassifySharesClass(
    currentCompany.orgNumber,
    { onSuccess: (eventId) => onSuccess(eventId) }
  );

  const reclassifySharesClassRangeMutation = useReclassifySharesRange(
    currentCompany.orgNumber,
    { onSuccess: (eventId) => onSuccess(eventId) }
  );

  const mutation = {
    class: reclassifySharesClassMutation,
    range: reclassifySharesClassRangeMutation,
    shareholders: reclassifySharesClassRangeMutation,
  }[formValues.type || "class"];

  useEffect(() => {
    setFormData((data) => ({ ...data, formId: reclassificationFormId }));
  }, [setFormData]);

  useEffect(() => {
    setFormData((data) => ({
      ...data,
      loading: mutation.isLoading,
    }));
  }, [mutation.isLoading, setFormData]);

  const onSubmit = (data: FormProps) => {
    if (
      data.targetShareClassType === "existing" &&
      selectedShareTypes.some((x) => x === data.targetExistingShareClass)
    ) {
      setError("targetExistingShareClass", {
        type: "manual",
        message: i18n.t("error.verification.reclassify.sameClass"),
      });
      return;
    }
    if (data.type === "range") {
      const shareRangeErrors = data.fromShareRanges.map((range) => {
        const matchingBlock = shareblocksArray.find(
          (x) =>
            range.start &&
            range.end &&
            x.start <= range.start &&
            x.end >= range.end
        );
        if (!matchingBlock) {
          return i18n.t("error.validation.blocks.overlaps");
        }
        if (matchingBlock.cancelled) {
          return i18n.t("error.verification.shares.cancelled");
        }
        return null;
      });
      if (shareRangeErrors.some((x) => x)) {
        for (let i = 0; i < shareRangeErrors.length; i++) {
          const error = shareRangeErrors[i];
          if (error !== null) {
            setError(`fromShareRanges.${i}.start`, {
              type: "manual",
              message: error,
            });
            setError(`fromShareRanges.${i}.end`, {
              type: "manual",
              message: error,
            });
          }
        }
        return;
      }
    }
    const toClass =
      data.targetShareClassType === "existing"
        ? { name: data.targetExistingShareClass }
        : {
            name: data.newShareClassName.trim(),
            voteValue: data.newShareClassVoteValue,
            condition: data.newShareCondition,
          };
    if (data.type === "class") {
      reclassifySharesClassMutation.mutate({
        date: data.date,
        fromClass: data.fromShareClass,
        toClass,
      });
    } else if (data.type === "shareholders") {
      const ranges = shareBlocks
        .filter((h) => data.fromShareholders.includes(h.id))
        .reduce(
          (prev, curr) => [...prev, ...curr.blocks.filter((b) => !b.cancelled)],
          [] as { start: number; end: number }[]
        );
      reclassifySharesClassRangeMutation.mutate({
        date: data.date,
        shareRanges: ranges.map((r) => ({
          start: r.start,
          end: r.end,
        })),
        toClass,
      });
    } else {
      reclassifySharesClassRangeMutation.mutate({
        date: data.date,
        shareRanges: validRanges(data.fromShareRanges).map((r) => ({
          start: r.start,
          end: r.end,
        })),
        toClass,
      });
    }
  };

  const typeOptions: ActionOption[] = [
    {
      value: "class",
      title: i18n.t("events.reclassification.form.type.option1.title"),
    },
    {
      value: "range",
      title: i18n.t("events.reclassification.form.type.option2.title"),
    },
    {
      value: "shareholders",
      title: i18n.t("events.reclassification.form.type.option3.title"),
    },
  ];

  const targetShareClassTypeOptions: TargetShareClassTypeOption[] = [
    {
      value: "existing",
      title: i18n.t(
        "events.reclassification.form.targetShareClass.option1.title"
      ),
    },
    {
      value: "new",
      title: i18n.t(
        "events.reclassification.form.targetShareClass.option2.title"
      ),
    },
  ];

  const shareholdersQuery = useShareholderBlocksQuery(currentCompany.orgNumber);
  const shareHolders = useMemo(
    () => shareholdersQuery.data || [],
    [shareholdersQuery.data]
  );

  const selectedShareTypes = useMemo(() => {
    if (formValues.type === "class") {
      return [formValues.fromShareClass];
    }
    if (formValues.type === "shareholders") {
      return shareBlocks
        .filter((b) => formValues.fromShareholders?.includes(b.id))
        .reduce(
          (prev, curr) => [
            ...prev,
            ...curr.blocks.filter((b) => !b.cancelled).map((b) => b.type),
          ],
          [] as string[]
        );
    }
    const validShareRanges = validRanges(formValues.fromShareRanges);
    const selectedBlocksByType = getSelectedBlocksByTypeFromShareholders(
      validShareRanges,
      shareHolders
    );
    return Object.keys(selectedBlocksByType);
  }, [
    formValues.fromShareClass,
    formValues.fromShareRanges,
    formValues.fromShareholders,
    formValues.type,
    shareHolders,
  ]);

  useEffect(() => {
    setFormData((data) => {
      if (_.isEqual(data.selectedShareTypes, selectedShareTypes)) {
        return data;
      }
      return { ...data, selectedShareTypes };
    });
  }, [selectedShareTypes, setFormData]);

  return (
    <div>
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h1 className="tw-text-2xl tw-font-medium">
            {i18n.t("events.reclassification.title")}
          </h1>
        </div>
      </header>
      <form
        id={reclassificationFormId}
        className="tw-space-y-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormGroup>
          <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
          <Controller
            control={control}
            render={({ field: { ref, name, onChange, value }, fieldState }) => (
              <>
                <Input
                  id="date"
                  value={value}
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  type="date"
                  className="tw-w-full"
                  max={dateToIsoString(new Date())}
                  min={lastEventDate && dateToIsoString(lastEventDate.date)}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            name="date"
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        <div className="tw-space-y-4">
          <FormGroup>
            <Controller
              control={control}
              render={({ field: { name, onChange, value }, fieldState }) => (
                <>
                  <RadioGroup
                    value={value || null}
                    onChange={onChange}
                    name={name}
                  >
                    <RadioGroup.Label>
                      <FormLabel htmlFor={name} className="tw-mb-2">
                        {i18n.t("events.reclassification.form.type.label")}
                      </FormLabel>
                    </RadioGroup.Label>
                    <div className="tw-flex tw-flex-col tw-gap-2 md:tw-flex-row">
                      {typeOptions.map((option) => (
                        <RadioGroup.Option
                          key={option.value}
                          value={option.value}
                        >
                          {({ checked }) => (
                            <RadioGroup.OptionContent checked={checked}>
                              <Description title={option.title} />
                            </RadioGroup.OptionContent>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              name="type"
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          {formValues.type === "class" && (
            <FormGroup>
              <FormLabel htmlFor="from">{i18n.t("label.from")}</FormLabel>
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <>
                    <Select
                      name="fromShareClassSelect"
                      options={shareTypes.filter((x) => x.isUsed)}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      value={shareTypes
                        .filter((x) => x.isUsed)
                        .find(({ name }) => name === value)}
                      onChange={(newValue) => onChange(newValue?.name)}
                    />
                    <FormError>{fieldState.error?.message}</FormError>
                  </>
                )}
                name="fromShareClass"
                rules={{ required: i18n.t("error.validation.required") }}
              />
            </FormGroup>
          )}
          {formValues.type === "range" && (
            <Controller
              control={control}
              render={({ field: { onChange, value }, fieldState }) => (
                <>
                  <div className="tw-flex tw-flex-col tw-gap-4">
                    <div className="tw-basis-3/4">
                      <SelectRange
                        allShareBlocks={blocksWithEntity}
                        value={value}
                        onChange={onChange}
                        fieldKey="fromShareRanges"
                        register={register}
                        errors={errors.fromShareRanges as ShareRangeError[]}
                      />
                      <FormError>{fieldState.error?.message}</FormError>
                    </div>

                    <div className="tw-basis-1/4 tw-space-y-2 tw-rounded tw-border tw-p-4 print:tw-border-none max-md:tw-hidden">
                      <h4>{i18n.t("label.availableShares")}</h4>
                      <h6 className="tw-font-bold">
                        {i18n.t("label.shareClasses")}
                      </h6>
                      {shareblocksByName.map(([name, shareblocks]) => (
                        <ShareClass
                          key={name}
                          name={name}
                          shareblocks={shareblocks}
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
              name="fromShareRanges"
              rules={{
                validate: (value) => {
                  const totalShares = ledgerQuery.data?.shares.total || 1;
                  const ranges = validRanges(value);
                  if (value.length === 0) {
                    return i18n.t("error.validation.required");
                  }
                  for (let i = 0; i < value.length; i++) {
                    if (value[i]!.start! > value[i]!.end!) {
                      return i18n.t("error.validation.range.invalid");
                    }
                  }
                  const rangeTotal = sumRanges(ranges);
                  if (rangeTotal > totalShares) {
                    return i18n.t("error.validation.range.max", {
                      max: totalShares,
                    });
                  }
                  if (hasOverlappingRanges(ranges)) {
                    return i18n.t("error.validation.overlaps");
                  }

                  return true;
                },
              }}
            />
          )}
          {formValues.type === "shareholders" && (
            <FormGroup>
              <FormLabel htmlFor="fromShareholders">
                {i18n.t("events.reclassification.form.shareholders.label")}
              </FormLabel>
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <>
                    <SelectEntity
                      id="fromShareholders"
                      options={shareholders}
                      value={value}
                      onChange={onChange}
                      shareBlocks={shareHolders}
                      isClearable
                      isMulti
                    />
                    <FormError>{fieldState.error?.message}</FormError>
                  </>
                )}
                name="fromShareholders"
                rules={{ required: i18n.t("error.validation.required") }}
              />
            </FormGroup>
          )}
        </div>
        <div className="tw-space-y-4">
          <FormGroup>
            <Controller
              control={control}
              render={({ field: { name, onChange, value }, fieldState }) => (
                <>
                  <RadioGroup value={value} onChange={onChange} name={name}>
                    <RadioGroup.Label>
                      <FormLabel htmlFor={name} className="tw-mb-2">
                        {i18n.t(
                          "events.reclassification.form.targetShareClass.label"
                        )}
                      </FormLabel>
                    </RadioGroup.Label>
                    <div className="tw-flex tw-flex-col tw-gap-2 md:tw-flex-row">
                      {targetShareClassTypeOptions.map((option) => (
                        <RadioGroup.Option
                          key={option.value}
                          value={option.value}
                        >
                          {({ checked }) => (
                            <RadioGroup.OptionContent checked={checked}>
                              <Description title={option.title} />
                            </RadioGroup.OptionContent>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              name="targetShareClassType"
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          {formValues.targetShareClassType === "existing" && (
            <FormGroup>
              <FormLabel htmlFor="to">{i18n.t("label.to")}</FormLabel>
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <>
                    <Select
                      name="toShareClassSelect"
                      options={shareTypes.filter(
                        (type) => !selectedShareTypes.includes(type.name)
                      )}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      value={shareTypes.find(({ name }) => name === value)}
                      onChange={(newValue) => onChange(newValue?.name)}
                    />
                    <FormError>{fieldState.error?.message}</FormError>
                  </>
                )}
                rules={{ required: i18n.t("error.validation.required") }}
                name="targetExistingShareClass"
              />
            </FormGroup>
          )}
          {formValues.targetShareClassType === "new" && (
            <>
              <ShareTypeAutofill
                existingClasses={shareTypes.map((l) => l.name)}
                onClick={(c) => {
                  setValue("newShareClassName", c.name);
                  if (c.votes) {
                    setValue("newShareClassVoteValue", c.votes);
                  }
                }}
              />
              <FormGroup>
                <FormLabel htmlFor="newShareClassName">
                  {i18n.t("label.name")}
                </FormLabel>
                <Input
                  id="newShareClassName"
                  {...register("newShareClassName", {
                    validate: (value) => {
                      if (!value.trim()) {
                        return i18n.t("error.validation.required");
                      }
                      if (
                        shareTypes.some(
                          (sibling) =>
                            sibling.name.trim().toLowerCase() ===
                            value.trim().toLowerCase()
                        )
                      ) {
                        return i18n.t("error.validation.unique");
                      }

                      return true;
                    },
                  })}
                />
                <FormError>{errors.newShareClassName?.message}</FormError>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="newShareClassVoteValue">
                  {i18n.t("label.numberOfVotesPerShare")}
                </FormLabel>
                <Input
                  id="newShareClassVoteValue"
                  {...register("newShareClassVoteValue", {
                    required: i18n.t("error.validation.required"),
                    valueAsNumber: true,
                    validate: (val) => {
                      if (Number.isNaN(val)) {
                        return i18n.t("error.validation.required");
                      }
                      const range = {
                        min: maxVote / 10,
                        max: minVote * 10,
                      };
                      if (val < range.min || val > range.max) {
                        return i18n.t("error.validation.range.votes", range);
                      }

                      return true;
                    },
                  })}
                  type="number"
                />
                <FormError>{errors.newShareClassVoteValue?.message}</FormError>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="newShareCondition" isOptional>
                  {i18n.t("shares.restrictiveConditions")}
                </FormLabel>
                <Controller
                  name="newShareCondition"
                  control={control}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <>
                      <SelectClauses
                        value={value}
                        onChange={onChange}
                        menuPlacement="top"
                      />
                      <FormError>{fieldState.error?.message}</FormError>
                    </>
                  )}
                  rules={{ required: i18n.t("error.validation.required") }}
                />
              </FormGroup>
            </>
          )}
        </div>
        {mutation.error && <FormErrorList error={mutation.error} />}
      </form>
    </div>
  );
};

export default Reclassification;
