import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useLedgerQuery } from "../../../api/blockchain/company";
import { useCompanyDetailsQuery } from "../../../api/rest/administration";
import { useIssueShares } from "../../../api/rest/events";
import { EventFormProps } from "../../../components/AddEvents/EventsWizard.utils";
import { Button } from "../../../components/design-system/Button";
import { Description } from "../../../components/design-system/Description";
import { Dialog } from "../../../components/design-system/Dialog";
import { FormErrorList } from "../../../components/design-system/FormGroup";
import { RadioGroup } from "../../../components/design-system/RadioGroup";
import {
  EventFormWrapper,
  EventSummarySectionList,
  TEventSummaryMetric,
} from "../../../components/EventSummary";
import { Ledger } from "../../../types/models/shares";
import { calcSumWithinRange } from "../../../utils/shares";
import InvestmentAmount from "./InvestmentAmount";
import NewShareClasses from "./NewShareClasses";
import ShareBlocks from "./ShareBlocks";
import type { ShareIssueFormState } from "./types";

const formId = "issue-shares";

const getMetrics = (totalShares?: number, ledgerData?: Ledger) => {
  const beforeShareCapital = ledgerData?.capital || 1;
  const beforeTotalShares = ledgerData?.shares.total || 1;
  const quota = beforeShareCapital / beforeTotalShares;

  const afterTotalShares = beforeTotalShares + (totalShares || 0);
  const afterShareCapital = quota * afterTotalShares;

  return {
    shareCapital: { before: beforeShareCapital, after: afterShareCapital },
    quotaValue: {
      before: quota,
      after: quota,
    },
    numberOfShares: { before: beforeTotalShares, after: afterTotalShares },
  };
};

const IssueShares = ({
  currentCompany,
  onSuccess,
  setFormData,
  autofillData,
}: EventFormProps) => {
  const i18n = useTranslation();
  const [showAddShareClass, setShowAddShareClass] = useState(false);
  const [showShareRangeWarning, setShowShareRangeWarning] = useState(false);
  const companyInfoQuery = useCompanyDetailsQuery(currentCompany.orgNumber);
  const ledgerQuery = useLedgerQuery(currentCompany.orgNumber, "");
  const companyMaxShares =
    companyInfoQuery.data?.sharesInformation?.shareLimits.shares?.max;
  const maxShares = companyMaxShares
    ? companyMaxShares - (ledgerQuery.data?.shares.total || 0)
    : undefined;

  const form = useForm<ShareIssueFormState>({
    mode: "onChange",
    defaultValues: {
      shareCapital: {
        formationDate: autofillData?.date,
        capitalAmount: autofillData?.payment,
        totalShares: autofillData?.shares,
      },
      shareClasses: [],
      shareRanges: [],
    },
  });
  const { watch, handleSubmit, setError } = form;

  const data = watch();
  const changedAutofillData =
    data.shareCapital.formationDate !== autofillData?.date ||
    data.shareCapital.capitalAmount !== autofillData?.payment ||
    data.shareCapital.totalShares !== autofillData?.shares;

  const diff =
    data.shareRanges.reduce(
      (prev, curr) => prev + calcSumWithinRange(curr),
      0
    ) - (data.shareCapital.totalShares || 0);

  const issueSharesMutation = useIssueShares(currentCompany.orgNumber, {
    onSuccess: (eventId) => {
      onSuccess(eventId);
    },
  });
  const { isLoading } = issueSharesMutation;

  const metrics = getMetrics(data.shareCapital.totalShares, ledgerQuery.data);
  const metricsWithLabel: Record<string, TEventSummaryMetric> = {
    shareCapital: {
      label: `${i18n.t("label.shareCapital")} (${
        currentCompany.settings?.currency
      })`,
      format: "number",
      ...metrics.shareCapital,
    },
    quotaValue: {
      label: `${i18n.t("label.quotaValue")} (${
        currentCompany.settings?.currency
      })`,
      format: "number",
      ...metrics.quotaValue,
    },
    numberOfShares: {
      label: i18n.t("label.shares"),
      format: "number",
      ...metrics.numberOfShares,
    },
  };

  useEffect(() => {
    setFormData((d) => ({ ...d, formId }));
  }, [setFormData]);

  useEffect(() => {
    setFormData((d) => ({
      ...d,
      loading: isLoading,
    }));
  }, [isLoading, setFormData]);

  const onSubmit = () => {
    if (data.shareRanges.length > 1 && !showShareRangeWarning) {
      setShowShareRangeWarning(true);
      return;
    }
    if (diff !== 0) {
      setError("shareRanges", {
        type: "manual",
        message: i18n.t("error.verification.shares.issue"),
      });
      return;
    }

    issueSharesMutation.mutate(data);
  };

  const shareClassOptions = [
    { value: false, title: i18n.t("events.issue.shareClasses.existing") },
    { value: true, title: i18n.t("events.issue.shareClasses.new") },
  ];

  return (
    <div>
      <EventFormWrapper
        summary={
          <EventSummarySectionList metrics={Object.values(metricsWithLabel)} />
        }
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          id={formId}
          className="tw-flex tw-flex-col tw-gap-6"
        >
          <div>
            <h1 className="tw-text-2xl tw-font-medium ">
              {i18n.t("events.issue.page.title")}
            </h1>
            <p>{i18n.t("events.issue.page.description")}</p>
          </div>
          <InvestmentAmount
            currentCompany={currentCompany}
            form={form}
            maxShares={maxShares}
            isAutofilled={!!autofillData && !changedAutofillData}
          />
          <RadioGroup
            value={showAddShareClass}
            onChange={(newValue: boolean) => {
              setShowAddShareClass(newValue);
            }}
            name="type"
            className="tw-space-y-2"
          >
            <RadioGroup.Label
              htmlFor="type"
              className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
            >
              {i18n.t("events.issue.shareClasses.label")}
            </RadioGroup.Label>
            <div className="tw-flex tw-flex-col tw-gap-2 md:tw-flex-row">
              {shareClassOptions.map((option) => (
                <RadioGroup.Option
                  key={option.value.toString()}
                  value={option.value}
                >
                  {({ checked }) => (
                    <RadioGroup.OptionContent checked={checked}>
                      <Description title={option.title} />
                    </RadioGroup.OptionContent>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
          {showAddShareClass && (
            <NewShareClasses currentCompany={currentCompany} form={form} />
          )}
          <ShareBlocks currentCompany={currentCompany} form={form} />
          {issueSharesMutation.error && (
            <FormErrorList error={issueSharesMutation.error} />
          )}
        </form>
      </EventFormWrapper>
      {showShareRangeWarning && (
        <Dialog
          isOpen
          title={i18n.t("events.issue.ranges.warning.title")}
          onClose={() => setShowShareRangeWarning(false)}
          isLoading={isLoading}
          actions={
            <>
              <Button
                onClick={() => setShowShareRangeWarning(false)}
                disabled={isLoading}
              >
                {i18n.t("label.cancel")}
              </Button>
              <Button
                type="submit"
                variant="solid"
                color="primary"
                form={formId}
                isLoading={isLoading}
              >
                {i18n.t("label.continue")}
              </Button>
            </>
          }
        >
          {i18n.t("events.issue.ranges.warning.description")}
        </Dialog>
      )}
    </div>
  );
};

export { IssueShares };
