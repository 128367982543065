import { useTranslation } from "react-i18next";

import { FormRadioGroup } from "../../../components/design-system/RadioGroup";

type TransferMethod = "basic" | "range";

type TransferMethodSelectorProps = {
  value?: TransferMethod;
  onChange: (value: TransferMethod) => void;
};

const TransferMethodSelector = ({
  value,
  onChange,
}: TransferMethodSelectorProps) => {
  const i18n = useTranslation();
  const typeOptions = [
    {
      value: "basic",
      title: i18n.t("events.transfer.form.type.option1.title"),
    },
    {
      value: "range",
      title: i18n.t("events.transfer.form.type.option2.title"),
    },
  ];

  return (
    <FormRadioGroup
      value={value ?? "basic"}
      onChange={(val) => onChange(val as TransferMethod)}
      id="type"
      label={i18n.t("events.transfer.form.type.label")}
      options={typeOptions}
    />
  );
};

export { TransferMethodSelector };
export type { TransferMethod };
