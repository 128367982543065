import { useTranslation } from "react-i18next";

import { useCompanyDetailsQuery } from "../../../../../api/rest/administration";
import { Button } from "../../../../../components/design-system/Button";
import { useCurrentCompany } from "../../../../../context/account";
import logo from "../../../../companies/[companyId]/bv.svg";

type ShareTypeAutofillProps = {
  existingClasses: string[];
  onClick: (data: { name: string; votes?: number }) => void;
};

const ShareTypeAutofill = ({
  existingClasses,
  onClick,
}: ShareTypeAutofillProps) => {
  const i18n = useTranslation();
  const currentCompany = useCurrentCompany();
  const companyInfoQuery = useCompanyDetailsQuery(currentCompany?.orgNumber);
  const companyShareClasses =
    companyInfoQuery.data?.sharesInformation?.shareClasses || [];

  if (companyShareClasses.length === 0) {
    return null;
  }

  return (
    <div className="tw-my-4 tw-rounded tw-bg-info-secondary tw-p-4 tw-text-info-primary">
      <div className="tw-flex tw-gap-4">
        <img src={logo} alt="bolagsverket-logo" />
        <div>
          <p className="tw-text-sm tw-font-medium">
            {i18n.t("events.issue.newClasses.autofill.title")}
          </p>
          <p className="tw-text-sm">
            {i18n.t("events.issue.newClasses.autofill.description")}
          </p>
        </div>
      </div>
      <div className="tw-mt-4 tw-flex tw-flex-col tw-gap-4">
        {companyShareClasses?.map((c) => (
          <div className="tw-flex tw-justify-between" key={c.name}>
            <div>
              <p className="tw-text-sm tw-font-medium">{c.name}</p>
              {c.votes && (
                <p className="tw-text-sm">
                  {i18n.t("label.numberOfVotesPerShare.count", {
                    count: c.votes,
                  })}
                </p>
              )}
            </div>
            {existingClasses.includes(c.name) ? (
              <p className="tw-flex tw-items-center tw-text-sm tw-font-medium">
                {i18n.t("events.issue.newClasses.autofill.disabled")}
              </p>
            ) : (
              <Button
                variant="outline"
                color="secondary"
                size="md"
                onClick={() => {
                  onClick(c);
                }}
              >
                {i18n.t("events.issue.newClasses.autofill.add")}
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export { ShareTypeAutofill };
