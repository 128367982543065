import { ShareFat } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { Button } from "../../components/design-system/Button";
import { Menu } from "../../components/design-system/Menu";
import { MenuItemsAlignment } from "../../components/design-system/Select";
import { clsxm } from "../../utils/tailwind";
import { ViewForm } from "./ViewForm";

type CreateViewDialogProps = {
  title: string;
  orgNumber: string;
  onSuccess?: () => void;
  menuItemsAlignment?: MenuItemsAlignment;
  defaultValues?: {
    viewId?: string;
    shareLedgerVersion: string;
    expiryDate?: string;
    sharedEmails?: { email: string; invitationSent: boolean }[];
  };
};

const formId = "create-view-form";

const ViewMenu = ({
  title,
  orgNumber,
  onSuccess,
  menuItemsAlignment,
  defaultValues,
}: CreateViewDialogProps) => {
  const i18n = useTranslation();
  const [loading, setLoading] = useState(false);
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <Menu className="tw-w-fit">
      {({ close }) => (
        <>
          <Menu.Button
            type="button"
            className="tw-flex tw-items-center tw-p-4 max-md:tw-p-3"
            aria-label="Export"
            isDropdown={false}
          >
            <span className="tw-hidden md:tw-mr-2 md:tw-block">
              {i18n.t("shareholders.share")}
            </span>
            <ShareFat />
          </Menu.Button>
          <Menu.Items
            className={clsxm(
              "tw-p-4 max-md:tw-mx-4 max-md:tw-w-[calc(100%-32px)] md:tw-min-w-[350px]",
              { "md:tw--ml-[calc(350px-109px)]": !isTabletOrMobileDevice }
            )}
          >
            <h2 className="tw-text-xl tw-font-medium">{title}</h2>
            <p className="tw-pb-4 tw-text-sm">
              {i18n.t("views.create.description")}
            </p>
            <div className="tw-space-y-4">
              <ViewForm
                orgNumber={orgNumber}
                onSuccess={() => {
                  if (onSuccess) {
                    onSuccess();
                  }
                  close();
                }}
                menuItemsAlignment={menuItemsAlignment}
                defaultValues={defaultValues}
                setLoading={setLoading}
              />
              <Button
                className="tw-w-full"
                variant="solid"
                color="primary"
                type="submit"
                form={formId}
                isLoading={loading}
              >
                {i18n.t(
                  defaultValues?.viewId
                    ? "views.edit.submit"
                    : "views.create.submit"
                )}
              </Button>
            </div>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

export { ViewMenu };
