import type { MultiValueProps as SelectMultiValueProps } from "../Select";
import { components } from "../Select";
import { ShareBlock } from "./SelectRange";

type MultiValueProps = SelectMultiValueProps<ShareBlock, true>;

const MultiValue: React.FunctionComponent<MultiValueProps> = (props) => {
  const { data: block } = props;

  return (
    <components.MultiValue {...props}>
      <div className="tw-flex tw-gap-1">
        <div className="tw-flex tw-gap-2">
          <span>{`${block.start} - ${block.end}`}</span>
          <span>{block.type}</span>
          <span>{block.entity.name}</span>
        </div>
      </div>
    </components.MultiValue>
  );
};

export { MultiValue };
