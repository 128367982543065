import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  useAddSharedViewMutation,
  useEditSharedViewMutation,
} from "../../api/rest/views";
import { Alert } from "../../components/design-system/Alert";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../components/design-system/FormGroup";
import { Input } from "../../components/design-system/Input";
import { MenuItemsAlignment } from "../../components/design-system/Select";
import { TagsInput } from "../../components/design-system/TagsInput";
import { SelectVersion } from "../../components/SelectVersion";
import { dateToIsoString } from "../../utils/date";
import { emailRegex } from "../../utils/validation";
import { useLedgerVersions } from "../CompanyShares/useLedgerVersions";

type ViewFormProps = {
  orgNumber: string;
  onSuccess: () => void;
  menuItemsAlignment?: MenuItemsAlignment;
  setLoading: (value: boolean) => void;
  defaultValues?: {
    viewId?: string;
    shareLedgerVersion: string;
    expiryDate?: string;
    sharedEmails?: { email: string; invitationSent: boolean }[];
  };
};

const formId = "create-view-form";

const endOfDayTimestamp = (date: Date) => {
  date.setHours(23, 59, 59, 999);
  return date.toISOString();
};

const addDays = (date: Date, days: number) => {
  date.setDate(date.getDate() + days);
  return date;
};

const ViewForm = ({
  orgNumber,
  onSuccess,
  menuItemsAlignment,
  defaultValues,
  setLoading,
}: ViewFormProps) => {
  const i18n = useTranslation();
  const ledgerVersions = useLedgerVersions(orgNumber);
  const [viewId, setViewId] = useState<string | undefined>(
    defaultValues?.viewId
  );
  const [invalidEmails, setInvalidEmails] = useState<string[]>(
    (defaultValues?.sharedEmails || [])
      .filter((i) => !i.invitationSent)
      .map((i) => i.email)
  );

  const editMutation = useEditSharedViewMutation(orgNumber, viewId, {
    onSuccess: (data) => {
      if (data.failedEmailInvitations.length > 0) {
        setInvalidEmails(data.failedEmailInvitations.map((i) => i.email));
      } else {
        onSuccess();
      }
    },
  });
  const addMutation = useAddSharedViewMutation(orgNumber, {
    onSuccess: (data) => {
      if (data.failedEmailInvitations.length > 0) {
        setInvalidEmails(data.failedEmailInvitations.map((i) => i.email));
        setViewId(data.viewId);
      } else {
        onSuccess();
      }
    },
  });
  const isEdit = !!viewId;
  const mutation = isEdit ? editMutation : addMutation;

  useEffect(() => {
    setLoading(mutation.isLoading);
  }, [mutation.isLoading]);

  const { control, handleSubmit, setError, clearErrors } = useForm({
    mode: "onSubmit",
    defaultValues: {
      shareLedgerVersion: defaultValues?.shareLedgerVersion || "",
      expiry: defaultValues?.expiryDate
        ? endOfDayTimestamp(new Date(defaultValues.expiryDate))
        : "",
      emails: defaultValues?.sharedEmails
        ? defaultValues.sharedEmails.map(({ email }) => email)
        : [],
    },
  });

  return (
    <form
      className="tw-space-y-4"
      onSubmit={(event) => handleSubmit((data) => mutation.mutate(data))(event)}
      id={formId}
    >
      <FormGroup>
        <FormLabel htmlFor="shareLedgerVersion">
          {i18n.t("views.create.version")}
        </FormLabel>
        <Controller
          control={control}
          name="shareLedgerVersion"
          render={({ field: { onChange, value }, fieldState }) => (
            <>
              <SelectVersion
                onChange={(v) => onChange(v?.formatedValue)}
                availableVersions={ledgerVersions}
                selectedVersion={ledgerVersions.find(
                  (x) => x.formatedValue === value
                )}
                isDisabled={isEdit}
                menuItemsAlignment={menuItemsAlignment}
              />
              <FormError>{fieldState.error?.message}</FormError>
            </>
          )}
          rules={{ required: i18n.t("error.validation.required") }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel htmlFor="expiry">{i18n.t("views.create.expiry")}</FormLabel>
        <Controller
          control={control}
          render={({ field: { ref, name, onChange, value }, fieldState }) => (
            <>
              <Input
                id="expiry"
                value={value.split("T")[0]}
                ref={ref}
                name={name}
                onChange={(e) =>
                  onChange(endOfDayTimestamp(new Date(e.target.value)))
                }
                type="date"
                className="tw-w-full"
                min={dateToIsoString(addDays(new Date(), 1))}
              />
              <FormError>{fieldState.error?.message}</FormError>
            </>
          )}
          name="expiry"
          rules={{ required: i18n.t("error.validation.required") }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>{i18n.t("views.create.emails")}</FormLabel>
        <Controller
          control={control}
          render={({ field: { name, onChange, value }, fieldState }) => (
            <>
              <TagsInput
                name={name}
                value={value}
                placeholder={i18n.t("views.create.emails.placeholder")}
                onChange={onChange}
                validate={(tag, existingTags) => {
                  if (existingTags.includes(tag)) {
                    setError("emails", {
                      type: "manual",
                      message: i18n.t("error.validation.duplicate"),
                    });
                    return false;
                  }
                  if (!emailRegex.test(tag)) {
                    setError("emails", {
                      type: "manual",
                      message: i18n.t("error.validation.format"),
                    });
                    return false;
                  }
                  clearErrors("emails");
                  return true;
                }}
              />
              <FormError>{fieldState.error?.message}</FormError>
            </>
          )}
          name="emails"
          rules={{
            validate: (data) => {
              if (data.some((i) => !emailRegex.test(i))) {
                return i18n.t("error.validation.format");
              }
              return true;
            },
          }}
        />
      </FormGroup>
      {invalidEmails.length > 0 && (
        <Alert type="error">
          {i18n.t("error.validation.emails.invites", {
            emails: invalidEmails.join(","),
          })}
        </Alert>
      )}
      {mutation.error && <FormErrorList error={mutation.error} />}
    </form>
  );
};

export { ViewForm };
