import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  useShareblocksQuery,
  useShareholderBlocksQuery,
} from "../../../api/blockchain/company";
import { useEntitiesQuery } from "../../../api/rest/entities";
import { useTransferShares } from "../../../api/rest/events";
import { EventFormProps } from "../../../components/AddEvents/EventsWizard.utils";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import { FormRadioGroup } from "../../../components/design-system/RadioGroup";
import { SelectEntity } from "../../../components/design-system/SelectEntity";
import { matchEntityToBlocks } from "../../../components/design-system/SelectRange";
import { AddEntity } from "../../../components/Entities/AddEntity";
import { InfoText } from "../../../components/InfoText";
import useLatestVersion from "../../../hooks/useLatestVersion";
import {
  FinalTransfer,
  SharesTransferForm,
} from "../../../types/models/shares";
import { dateToIsoString } from "../../../utils/date";
import {
  calcSumWithinRange,
  hasOverlappingRanges,
} from "../../../utils/shares";
import { CapitalAmount } from "../IssueShares/CapitalAmount";
import { MultiTransfer } from "./MultiTransfer";
import { TransferForm } from "./TransferForm";

const formId = "transfer-shares-form";
const singleTransferKey = "single";

type Method = "oneToOne" | "oneToMany" | "manyToOne";

const TransferShares = ({
  currentCompany,
  onSuccess,
  setFormData,
}: EventFormProps) => {
  const [method, setMethod] = useState<Method | undefined>("oneToOne");
  const i18n = useTranslation();
  const lastEventDate = useLatestVersion();

  const shareholdersQuery = useShareholderBlocksQuery(
    currentCompany?.orgNumber
  );
  const shareHolders = shareholdersQuery.data || [];

  const entitiesQuery = useEntitiesQuery(currentCompany?.orgNumber);
  const entities = entitiesQuery.data || [];
  const senders = entities.filter((entity) =>
    shareHolders.some((element) => element.id === entity.id)
  );

  const entitiesMap = Object.fromEntries(entities.map((e) => [e.id, e]));
  const shareBlockQuery = useShareblocksQuery(currentCompany?.orgNumber, "");
  const shareBlocks = shareBlockQuery.data || [];
  const blocksWithEntity = matchEntityToBlocks(shareBlocks, entitiesMap);

  const form = useForm<SharesTransferForm>({
    mode: "onChange",
    defaultValues: {
      transfers: {},
    },
  });
  const { transfers, senderId, recipientId } = form.watch();

  const mutation = useTransferShares(currentCompany.orgNumber, {
    onSuccess: (eventId) => onSuccess(eventId),
  });

  useEffect(() => {
    setFormData((data) => ({ ...data, formId }));
  }, []);

  useEffect(() => {
    setFormData((d) => ({
      ...d,
      loading: mutation.isLoading,
    }));
  }, [mutation.isLoading]);

  const onSubmit = (data: SharesTransferForm) => {
    if (method === "oneToMany" || method === "oneToOne") {
      if (!data.senderId) {
        form.setError("senderId", {
          type: "manual",
          message: i18n.t("error.validation.required"),
        });
        return;
      }
    } else if (!data.recipientId) {
      form.setError("recipientId", {
        type: "manual",
        message: i18n.t("error.validation.required"),
      });
      return;
    }
    for (const [id, t] of Object.entries(data.transfers)) {
      for (const transfer of t.transfers) {
        if (transfer.shareRanges.length === 0 && !transfer.sharesAmount) {
          form.setError(`transfers.${id}`, {
            type: "manual",
            message: i18n.t("error.validation.required"),
          });
          return;
        }
        if (transfer.shareRanges.length > 0 && transfer.sharesAmount) {
          form.setError(`transfers.${id}`, {
            type: "manual",
            message: i18n.t("error.validation.required"),
          });
          return;
        }
      }
    }
    if (method === "oneToMany") {
      const senderBlocks = shareBlocks.filter((b) => b.holder.id === senderId);
      const sharesByType = senderBlocks.reduce<Record<string, number>>(
        (prev, curr) => ({
          ...prev,
          [curr.type]: calcSumWithinRange(curr) + (prev[curr.type] || 0),
        }),
        {}
      );
      // Validate totals do not exceed maximum for class
      for (const [type, total] of Object.entries(sharesByType)) {
        const totalMatching = Object.values(data.transfers).reduce(
          (prev, item) =>
            prev +
            item.transfers.reduce(
              (count, t) =>
                count +
                (t.sharesAmount ||
                  t.shareRanges.reduce(
                    (p, r) =>
                      p + calcSumWithinRange({ start: r.start!, end: r.end! }),
                    0
                  )),
              0
            ),
          0
        );
        if (totalMatching > total) {
          form.setError("transfers", {
            type: "manual",
            message: i18n.t("error.validate.max.shareType", {
              type,
              max: total,
            }),
          });
          return;
        }
      }
      // Validate no overlapping ranges
      const allRanges = Object.values(data.transfers)
        .flatMap((item) => item.transfers)
        .flatMap((t) => t.shareRanges);
      if (hasOverlappingRanges(allRanges)) {
        form.setError("transfers", {
          type: "manual",
          message: i18n.t("error.verification.shares.overlappingRanges"),
        });
        return;
      }
    }

    if (method === "oneToOne") {
      const transfer = data.transfers[singleTransferKey]!;
      const finalTransfers: FinalTransfer[] = transfer.transfers.map((t) => ({
        senderId: data.senderId!,
        recipientId: data.recipientId!,
        shareType: t.shareType!,
        sharesAmount: t.sharesAmount!,
        shareRanges: t.shareRanges,
      }));
      mutation.mutate({
        ...data,
        transfers: finalTransfers,
      });
    } else {
      const finalTransfers: FinalTransfer[] = Object.entries(data.transfers)
        .map(([id, items]) =>
          items.transfers.map((t) => ({
            senderId: method === "oneToMany" ? data.senderId! : id,
            recipientId: method === "manyToOne" ? data.recipientId! : id,
            shareType: t.shareType!,
            sharesAmount: t.sharesAmount!,
            shareRanges: t.shareRanges,
          }))
        )
        .flat();
      mutation.mutate({
        ...data,
        transfers: finalTransfers,
      });
    }
  };

  const methodOptions: { value: Method; title: string }[] = [
    {
      value: "oneToOne",
      title: i18n.t("events.transfer.method.oneToOne"),
    },
    {
      value: "oneToMany",
      title: i18n.t("events.transfer.method.oneToMany"),
    },
    {
      value: "manyToOne",
      title: i18n.t("events.transfer.method.manyToOne"),
    },
  ];

  return (
    <div>
      <h1 className="tw-pb-6 tw-text-2xl tw-font-medium">
        {i18n.t("events.transfer.title")}
      </h1>
      <form
        className="tw-space-y-6"
        onSubmit={form.handleSubmit(onSubmit)}
        id={formId}
      >
        <FormGroup>
          <InfoText content={i18n.t("events.transfer.form.date.tooltip")}>
            <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
          </InfoText>
          <Controller
            control={form.control}
            render={({ field: { ref, name, onChange, value }, fieldState }) => (
              <>
                <Input
                  id="date"
                  value={value}
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  type="date"
                  className="tw-w-full"
                  max={dateToIsoString(new Date())}
                  min={lastEventDate && dateToIsoString(lastEventDate.date)}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            name="date"
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="purchasePrice" isOptional>
            {i18n.t("label.purchasePrice")}
          </FormLabel>
          <Controller
            control={form.control}
            name="purchasePrice"
            render={({ field: { onChange }, fieldState }) => (
              <>
                <CapitalAmount
                  currency={currentCompany.settings?.currency}
                  onChange={onChange}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
          />
        </FormGroup>
        <FormRadioGroup
          value={method}
          onChange={(value) => {
            form.setValue("transfers", {});
            form.setValue("senderId", "");
            form.setValue("recipientId", "");
            setMethod(value as Method);
          }}
          id="method"
          label={i18n.t("events.transfer.method.label")}
          options={methodOptions}
          layout="column"
        />
        {(method === "oneToOne" || method === "oneToMany") && (
          <FormGroup>
            <FormLabel htmlFor="senderId">{i18n.t("label.sender")}</FormLabel>
            <Controller
              control={form.control}
              render={({ field: { onChange, value }, fieldState }) => (
                <>
                  <SelectEntity
                    id="senderId"
                    options={senders.filter(({ id }) => id !== recipientId)}
                    value={value}
                    onChange={(val) => {
                      form.setValue("transfers", {});
                      onChange(val);
                    }}
                    shareBlocks={shareHolders}
                    isClearable
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              name="senderId"
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
        )}
        <Controller
          control={form.control}
          name="transfers"
          render={({ field: { onChange }, fieldState }) => (
            <>
              {method === "oneToOne" ? (
                <TransferForm
                  value={transfers[singleTransferKey]?.transfers || []}
                  id={singleTransferKey}
                  method={transfers[singleTransferKey]?.type ?? "basic"}
                  onChange={(data) => {
                    onChange({
                      [singleTransferKey]: {
                        ...transfers[singleTransferKey],
                        transfers: data,
                      },
                    });
                  }}
                  sender={senderId}
                  shareHolders={shareHolders}
                  shareBlocks={blocksWithEntity}
                  register={form.register}
                  control={form.control}
                  errors={form.formState.errors.transfers}
                />
              ) : (
                <MultiTransfer
                  value={transfers}
                  onChange={onChange}
                  type={method === "oneToMany" ? "recipient" : "sender"}
                  sender={senderId}
                  recipient={recipientId}
                  entities={entities}
                  shareHolders={shareHolders}
                  shareBlocks={blocksWithEntity}
                  register={form.register}
                  control={form.control}
                  trigger={form.trigger}
                  errors={form.formState.errors.transfers}
                />
              )}
              <FormError>{fieldState.error?.message}</FormError>
            </>
          )}
          rules={{
            validate: (val) => {
              if (Object.keys(val).length === 0) {
                return i18n.t("error.validation.required");
              }
              return true;
            },
          }}
        />
        {(method === "oneToOne" || method === "manyToOne") && (
          <FormGroup>
            <Controller
              control={form.control}
              render={({ field: { onChange, value }, fieldState }) => (
                <>
                  <div className="tw-flex tw-items-center tw-justify-between">
                    <FormLabel htmlFor="recipientId">
                      {i18n.t("label.recipient")}
                    </FormLabel>
                    <AddEntity
                      currentCompany={currentCompany}
                      onSuccess={(newEntity) => {
                        entitiesQuery.refetch();
                        onChange(newEntity.id);
                      }}
                    />
                  </div>
                  <SelectEntity
                    id="recipientId"
                    options={entities.filter(({ id }) => id !== senderId)}
                    value={value}
                    onChange={onChange}
                    shareBlocks={shareHolders}
                    isClearable
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              name="recipientId"
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
        )}
        {mutation.error && <FormErrorList error={mutation.error} />}
      </form>
    </div>
  );
};

export default TransferShares;
