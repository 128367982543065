import {
  Control,
  Controller,
  useFieldArray,
  UseFormRegister,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "../../../components/design-system/Button";
import {
  FormError,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { PlusIcon, TrashIcon } from "../../../components/design-system/icons";
import { Input } from "../../../components/design-system/Input";
import { Select } from "../../../components/design-system/Select";
import {
  ShareOwnership,
  SharesTransferForm,
  Transfer,
} from "../../../types/models/shares";
import { formatNumber } from "../../../utils/format";
import { TransferErrors } from "./TransferForm";

type TransferNumberRowProps = TransferNumberProps & {
  index: number;
  remove: () => void;
};

const TransferNumberRow = ({
  id,
  index,
  allValues,
  shareTypes,
  sender,
  register,
  control,
  errors,
  remove,
}: TransferNumberRowProps) => {
  const i18n = useTranslation();
  const shareType = useWatch({
    control,
    name: `transfers.${id}.transfers.${index}.shareType`,
  });
  const selectedShareType = shareTypes.find(({ type }) => type === shareType);
  const otherSelectShareTypes = allValues
    .map((t) => t.shareType)
    .filter((c, i) => c !== undefined && index !== i);
  const availableShareTypes = shareTypes.filter(
    (s) => !otherSelectShareTypes.includes(s.type)
  );
  const availableShares = selectedShareType?.amount || 0;

  return (
    <div className="tw-space-y-6 tw-rounded tw-border tw-p-4">
      <FormGroup>
        <FormLabel htmlFor={`transfers.${id}.transfers.${index}.sharesAmount`}>
          {i18n.t("label.numberOfShares")}
        </FormLabel>
        <div className="tw-grid tw-flex-1 tw-gap-2 md:tw-grid-cols-2">
          <div className="tw-space-y-2">
            <Input
              id={`transfers.${id}.transfers.${index}.sharesAmount`}
              {...register(`transfers.${id}.transfers.${index}.sharesAmount`, {
                required: i18n.t("error.validation.required"),
                ...(selectedShareType && {
                  min: {
                    value: 1,
                    message: i18n.t("error.validation.range", {
                      min: 1,
                      max: availableShares,
                    }),
                  },
                  max: {
                    value: availableShares,
                    message: i18n.t("error.validation.range", {
                      min: 1,
                      max: availableShares,
                    }),
                  },
                }),
                valueAsNumber: true,
              })}
              className="tw-flex-1"
              type="number"
              step={1}
            />
            <FormError>
              {errors &&
                errors[id] &&
                errors[id].transfers &&
                errors[id].transfers[index] &&
                errors[id].transfers[index].sharesAmount?.message}
            </FormError>
          </div>
          <div className="tw-space-y-2">
            <Controller
              control={control}
              render={({
                field: { onChange: change, value: v },
                fieldState,
              }) => (
                <>
                  <Select
                    options={availableShareTypes}
                    getOptionLabel={(option) =>
                      `${option.type} (${formatNumber(option.amount)})`
                    }
                    getOptionValue={(option) => option.type}
                    value={shareTypes.find(({ type }) => type === v)}
                    onChange={(newValue) => change(newValue?.type)}
                    isDisabled={!sender}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              rules={{
                required: i18n.t("error.validation.required"),
              }}
              name={`transfers.${id}.transfers.${index}.shareType`}
            />
          </div>
        </div>
      </FormGroup>
      <Button onClick={remove} className="tw-w-full" prefix={<TrashIcon />}>
        {i18n.t("events.transfer.transfers.remove")}
      </Button>
    </div>
  );
};

type TransferNumberProps = {
  id: string;
  allValues: Transfer[];
  shareTypes: ShareOwnership[];
  sender?: string;
  register: UseFormRegister<SharesTransferForm>;
  control: Control<SharesTransferForm>;
  errors?: TransferErrors;
};

const TransferNumber = (props: TransferNumberProps) => {
  const i18n = useTranslation();
  const { fields, remove, append } = useFieldArray({
    control: props.control,
    name: `transfers.${props.id}.transfers`,
  });

  return (
    <div className="tw-space-y-6">
      <Controller
        control={props.control}
        name={`transfers.${props.id}.transfers`}
        rules={{ required: i18n.t("error.validation.required") }}
        render={() => (
          <>
            {fields.map((value, index) => (
              <TransferNumberRow
                {...props}
                key={value.id}
                index={index}
                remove={() => remove(index)}
              />
            ))}
          </>
        )}
      />
      <Button
        prefix={<PlusIcon />}
        onClick={() =>
          append({
            sharesAmount: undefined,
            shareType: undefined,
            shareRanges: [],
          })
        }
        className="tw-w-full"
      >
        {i18n.t("events.transfer.transfers.addClass")}
      </Button>
    </div>
  );
};

export { TransferNumber };
