import { useTranslation } from "react-i18next";

import { Button } from "../../../components/design-system/Button";
import { Dialog } from "../../../components/design-system/Dialog";

type ProRataConfirmationProps = {
  onClose: () => void;
  onConfirm: () => void;
};

const ProRataConfirmation = ({
  onClose,
  onConfirm,
}: ProRataConfirmationProps) => {
  const i18n = useTranslation();

  return (
    <Dialog
      isOpen
      title={i18n.t("events.issue.shareBlocks.autofill.confirm.title")}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose}>{i18n.t("label.cancel")}</Button>
          <Button variant="solid" color="primary" onClick={onConfirm}>
            {i18n.t("events.issue.shareBlocks.autofill.confirm.button")}
          </Button>
        </>
      }
    >
      <p>{i18n.t("events.issue.shareBlocks.autofill.confirm.description")}</p>
    </Dialog>
  );
};

export { ProRataConfirmation };
