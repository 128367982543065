import { clsxm } from "../../../utils/tailwind";
import { Description } from "../Description";
import { FormLabel } from "../FormGroup";
import { RadioGroup } from "./RadioGroup";

type FormRadioGroupProps = {
  value?: string;
  onChange: (value: string) => void;
  id: string;
  label: string;
  options: {
    value: string;
    title: string;
    description?: string;
  }[];
  layout?: "row" | "column";
};

const FormRadioGroup = ({
  value,
  onChange,
  id,
  label,
  options,
  layout = "row",
}: FormRadioGroupProps) => (
  <RadioGroup value={value} onChange={onChange} name={id}>
    <RadioGroup.Label>
      <FormLabel htmlFor={id} className="tw-mb-2">
        {label}
      </FormLabel>
    </RadioGroup.Label>
    <div
      className={clsxm("tw-flex tw-flex-col tw-gap-2", {
        "md:tw-flex-row": layout === "row",
      })}
    >
      {options.map((option) => (
        <RadioGroup.Option key={option.value} value={option.value}>
          {({ checked }) => (
            <RadioGroup.OptionContent checked={checked}>
              <Description
                title={option.title}
                description={option.description}
              />
            </RadioGroup.OptionContent>
          )}
        </RadioGroup.Option>
      ))}
    </div>
  </RadioGroup>
);

export { FormRadioGroup };
