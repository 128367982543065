import { EntitiesMap } from "../../../types/models/entities";
import { Shareblock, ShareblockWithEntity } from "../../../types/models/shares";
import * as monitoring from "../../../utils/monitoring";
import { getEntityWithFallback } from "../../EventList/EventsTable/EventsTable.utils";

const matchEntityToBlocks = (
  shareBlocks: Shareblock[],
  entitiesMap: EntitiesMap
): ShareblockWithEntity[] =>
  shareBlocks
    .map((block) => {
      const entity = getEntityWithFallback(entitiesMap, block.holder);
      if (!("type" in entity)) {
        console.error("No entity fallback found");
        monitoring.captureException(
          new TypeError("Shareblock holder missing in entities"),
          { contexts: { entitiesMap, holder: block.holder } }
        );

        return null;
      }
      return { ...block, entity };
    })
    .filter((x) => x !== null);

export { matchEntityToBlocks };
