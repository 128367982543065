import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  useShareblocksQuery,
  useShareholderBlocksQuery,
  useShareTypesQuery,
} from "../../../../api/blockchain/company";
import { useEntitiesQuery } from "../../../../api/rest/entities";
import {
  useCreateRestrictionCase,
  useUpdateRestrictionCase,
} from "../../../../api/rest/restriction-case";
import { Button } from "../../../../components/design-system/Button";
import { Description } from "../../../../components/design-system/Description";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../../components/design-system/FormGroup";
import { Input } from "../../../../components/design-system/Input";
import { ProgressDialog } from "../../../../components/design-system/ProgressDialog";
import { RadioGroup } from "../../../../components/design-system/RadioGroup";
import {
  components,
  Option as SelectOption,
  Select,
} from "../../../../components/design-system/Select";
import { SelectEntity } from "../../../../components/design-system/SelectEntity";
import {
  matchEntityToBlocks,
  SelectRange,
  ShareRangeError,
} from "../../../../components/design-system/SelectRange";
import { AddEntity } from "../../../../components/Entities/AddEntity";
import { PageWrapper } from "../../../../components/PageWrapper";
import { SelectCurrency } from "../../../../components/SelectCurrency";
import { useRestrictiveConditionOptions } from "../../../../components/ShareTypes/SelectRestrictiveConditions";
import { APP_ROUTE } from "../../../../routes/constants";
import { CompanyInformation } from "../../../../types/models/administration";
import { CompanyInvolvement } from "../../../../types/models/company";
import {
  CreateRestrictionCase,
  RestrictionCase,
} from "../../../../types/models/restriction-case";
import { dateToIsoString } from "../../../../utils/date";
import { hasOverlappingRanges, validRanges } from "../../../../utils/shares";

type RestrictionCaseFormProps = {
  company: CompanyInformation | CompanyInvolvement;
  onClose: () => void;
  restrictionCase?: RestrictionCase;
};

const formId = "restriction-case-form";

const RestrictionCaseForm = ({
  company,
  onClose,
  restrictionCase,
}: RestrictionCaseFormProps) => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const [transfer, setTransfer] = useState<"number" | "range">(
    (restrictionCase?.shareRanges || []).length > 0 ? "range" : "number"
  );
  const conditionOptions = useRestrictiveConditionOptions();

  const transferOptions = [
    {
      value: "number",
      title: i18n.t("restrictionCase.transfer.number"),
    },
    {
      value: "range",
      title: i18n.t("restrictionCase.transfer.range"),
    },
  ];

  const shareholdersQuery = useShareholderBlocksQuery(company.orgNumber);
  const entitiesQuery = useEntitiesQuery(company.orgNumber);
  const shareTypesQuery = useShareTypesQuery(company.orgNumber, "");
  const entities = entitiesQuery.data || [];
  const entitiesMap = Object.fromEntries(entities.map((e) => [e.id, e]));
  const shareholders = shareholdersQuery.data || [];
  const shareTypes = shareTypesQuery.data || [];
  const shareholderEntities = entities.filter((e) =>
    shareholders.some((s) => s.id === e.id)
  );
  const shareBlockQuery = useShareblocksQuery(company.orgNumber, "");
  const shareBlocks = shareBlockQuery.data || [];
  const blocksWithEntity = matchEntityToBlocks(shareBlocks, entitiesMap);

  const typeOptions = [
    {
      value: "preemption",
      label: i18n.t("restrictionCase.type.preemption"),
      isDisabled: !shareTypes.some((s) => s.condition.preemption),
    },
    {
      value: "offerOfFirstRefusal",
      label: i18n.t("restrictionCase.type.offerOfFirstRefusal"),
      isDisabled: !shareTypes.some((s) => s.condition.offerOfFirstRefusal),
    },
  ];

  // TODO: Redirect on success
  const updateMutation = useUpdateRestrictionCase(
    company.orgNumber,
    restrictionCase?.id || "",
    {
      onSuccess: onClose,
    }
  );
  const createMutation = useCreateRestrictionCase(company.orgNumber, {
    onSuccess: (id) =>
      navigate(
        `${APP_ROUTE.COMPANIES}/${company.orgNumber}/restriction-cases/${id}`
      ),
  });
  const mutation = restrictionCase ? updateMutation : createMutation;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<CreateRestrictionCase>({
    mode: "onSubmit",
    defaultValues: restrictionCase
      ? {
          ...restrictionCase,
          shareholder: restrictionCase.shareholder.id,
          shareRanges:
            restrictionCase.shareRanges.length > 0
              ? restrictionCase.shareRanges
              : [],
        }
      : {
          shareRanges: [],
          currency: "SEK",
        },
  });

  function addMonths(dateString: string, months: number) {
    const date = new Date(dateString);
    date.setMonth(date.getMonth() + months);
    return date.toISOString().split("T")[0];
  }

  const { date, type, shareType, shareholder } = watch();
  const selectedShareholder = shareholders.find((x) => x.id === shareholder);
  const selectedShareholderTypes = selectedShareholder?.blocks.map(
    (b) => b.type
  );
  const selectedShareholdersSharesPerType = (
    selectedShareholder?.blocks || []
  ).reduce((prev, curr) => {
    return {
      ...prev,
      [curr.type]:
        (prev[curr.type] || 0) +
        (curr.cancelled ? 0 : curr.end - curr.start + 1),
    };
  }, {} as Record<string, number>);

  const shareTypeOptions = shareTypes.filter((s) => {
    const hasCondition =
      type === "preemption"
        ? s.condition.preemption
        : s.condition.offerOfFirstRefusal;
    const shareholderHasType = selectedShareholderTypes
      ? selectedShareholderTypes.includes(s.name)
      : true;
    return hasCondition && shareholderHasType;
  });
  const maxShares = selectedShareholdersSharesPerType[shareType] || Infinity;

  return (
    <ProgressDialog
      totalSteps={1}
      currentStep={0}
      actions={
        <>
          <Button
            type="submit"
            form={formId}
            color="primary"
            variant="solid"
            isLoading={mutation.isLoading}
          >
            {i18n.t("label.save")}
          </Button>
        </>
      }
      isLoading={mutation.isLoading}
      onClose={onClose}
    >
      <PageWrapper className="tw-max-w-3xl">
        <form
          className="tw-flex tw-flex-col tw-gap-4 tw-pb-4"
          id={formId}
          onSubmit={(event) => {
            event.stopPropagation();

            return handleSubmit((data) => {
              mutation.mutate({
                ...data,
                totalShares:
                  transfer === "number" ? data.totalShares : undefined,
                shareRanges:
                  transfer === "range"
                    ? data.shareRanges?.filter(
                        (x) => x.start !== undefined && x.end !== undefined
                      )
                    : [],
              });
            })(event);
          }}
        >
          <h1 className="tw-text-2xl tw-font-medium">
            {i18n.t(
              restrictionCase
                ? "restrictionCase.edit"
                : "restrictionCase.create"
            )}
          </h1>
          <FormGroup>
            <FormLabel htmlFor="type">
              {i18n.t("restrictionCase.type")}
            </FormLabel>
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, value, name }, fieldState }) => (
                <>
                  <Select
                    name={name}
                    value={typeOptions.find((t) => t.value === value)}
                    options={typeOptions}
                    onChange={(newValue) => onChange(newValue?.value)}
                    menuPosition="fixed"
                    isDisabled={!!restrictionCase}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="date">
              {i18n.t("restrictionCase.date")}
            </FormLabel>
            <Controller
              control={control}
              name="date"
              render={({
                field: { ref, name, onChange, value },
                fieldState,
              }) => (
                <>
                  <Input
                    id="date"
                    value={value}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    type="date"
                    className="tw-w-full"
                    max={dateToIsoString(new Date())}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                  <p className="tw-text-sm tw-text-secondary">
                    {i18n.t("restrictionCase.date.description")}
                  </p>
                </>
              )}
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="expiryDate">
              {i18n.t("restrictionCase.expiryDate")}
            </FormLabel>
            <Controller
              control={control}
              name="expiryDate"
              render={({
                field: { ref, name, onChange, value },
                fieldState,
              }) => (
                <>
                  <Input
                    id="expiryDate"
                    value={value}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    type="date"
                    className="tw-w-full"
                    min={date && addMonths(date, 1)}
                    max={date && addMonths(date, 2)}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                  <p className="tw-text-sm tw-text-secondary">
                    {i18n.t("restrictionCase.expiryDate.description")}
                  </p>
                </>
              )}
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="shareholder">
              {i18n.t(
                type === "preemption"
                  ? "restrictionCase.shareholder.current"
                  : "restrictionCase.shareholder.previous"
              )}
            </FormLabel>
            <Controller
              control={control}
              name="shareholder"
              render={({ field: { onChange, value }, fieldState }) => (
                <>
                  <SelectEntity
                    id="shareholder"
                    options={shareholderEntities}
                    value={value}
                    onChange={onChange}
                    shareBlocks={shareholders}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          {type === "offerOfFirstRefusal" && (
            <FormGroup className="tw-space-y-0">
              <Controller
                control={control}
                name="buyer"
                render={({ field: { onChange, value }, fieldState }) => (
                  <>
                    <div className="tw-flex tw-items-center tw-justify-between">
                      <FormLabel htmlFor="buyer">
                        {i18n.t("restrictionCase.buyer")}
                      </FormLabel>
                      <AddEntity
                        currentCompany={company}
                        onSuccess={(newEntity) => {
                          entitiesQuery.refetch();
                          onChange(newEntity.id);
                        }}
                      />
                    </div>
                    <SelectEntity
                      id="buyer"
                      options={entities.filter((x) => x.id !== shareholder)}
                      value={value}
                      onChange={onChange}
                      isClearable
                    />
                    <FormError>{fieldState.error?.message}</FormError>
                  </>
                )}
                rules={{ required: i18n.t("error.validation.required") }}
              />
            </FormGroup>
          )}
          <FormGroup>
            <FormLabel htmlFor="shareType">
              {i18n.t("label.shareType")}
            </FormLabel>
            <Controller
              control={control}
              name="shareType"
              render={({ field: { onChange, value }, fieldState }) => (
                <>
                  <Select
                    name="shareType"
                    options={shareTypeOptions}
                    components={{
                      Option: (props) => (
                        <SelectOption {...props}>
                          <Description
                            title={props.data.name}
                            description={Object.entries(props.data.condition)
                              .filter(([_, v]) => v)
                              .map(
                                ([key, _]) =>
                                  conditionOptions.find((c) => c.value === key)
                                    ?.label
                              )
                              .join(", ")}
                          />
                        </SelectOption>
                      ),
                      SingleValue: (props) => (
                        <components.SingleValue {...props}>
                          {props.data.name}
                        </components.SingleValue>
                      ),
                    }}
                    getOptionValue={(option) => option.name}
                    value={shareTypes.find(({ name }) => name === value)}
                    onChange={(newValue) => onChange(newValue?.name)}
                    menuPosition="fixed"
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          <RadioGroup
            value={transfer}
            onChange={(newValue: "number" | "range") => {
              setTransfer(newValue);
            }}
            name="type"
          >
            <RadioGroup.Label>
              <FormLabel htmlFor="type" className="tw-mb-2">
                {i18n.t("restrictionCase.transfer.selection.method")}
              </FormLabel>
            </RadioGroup.Label>
            <div className="tw-flex tw-flex-col tw-gap-2 md:tw-flex-row">
              {transferOptions.map((option) => (
                <RadioGroup.Option key={option.value} value={option.value}>
                  {({ checked }) => (
                    <RadioGroup.OptionContent checked={checked}>
                      <Description title={option.title} />
                    </RadioGroup.OptionContent>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
          {transfer === "number" ? (
            <FormGroup>
              <FormLabel htmlFor="totalShares">
                {i18n.t("restrictionCase.totalShares")}
              </FormLabel>
              <Input
                id="totalShares"
                type="number"
                step={1}
                {...register("totalShares", {
                  required: i18n.t("error.validation.required"),
                  valueAsNumber: true,
                  min: {
                    value: 1,
                    message: i18n.t("error.validation.range.min", { min: 1 }),
                  },
                  max: {
                    value: maxShares,
                    message: i18n.t("error.validation.range.max", {
                      max: maxShares,
                    }),
                  },
                })}
              />
              <FormError>{errors.totalShares?.message}</FormError>
            </FormGroup>
          ) : (
            <Controller
              control={control}
              render={({ field: { onChange, value }, fieldState }) => (
                <FormGroup>
                  <SelectRange
                    allShareBlocks={blocksWithEntity}
                    value={value}
                    fieldKey="shareRanges"
                    onChange={onChange}
                    register={register}
                    errors={errors.shareRanges as ShareRangeError[]}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </FormGroup>
              )}
              name="shareRanges"
              rules={{
                validate: (value) => {
                  if (!value) {
                    return i18n.t("error.validation.required");
                  }
                  const ranges = validRanges(value);
                  if (value.length === 0) {
                    return i18n.t("error.validation.required");
                  }
                  if (hasOverlappingRanges(ranges)) {
                    return i18n.t("error.validation.overlaps");
                  }
                  for (const r of ranges) {
                    if (r.start! > r.end!) {
                      return i18n.t("error.validation.range.invalid");
                    }
                    const block = shareBlocks.find(
                      (b) => b.start <= r.start! && b.end >= r.end!
                    );
                    if (!block) {
                      return i18n.t("error.validation.blocks.overlaps");
                    }
                    if (block.cancelled) {
                      return i18n.t("error.verification.shares.cancelled");
                    }
                    if (block.holder.id !== shareholder) {
                      return i18n.t("error.validation.shareholder");
                    }
                    if (block.type !== shareType) {
                      return i18n.t("error.validation.shareType");
                    }
                  }

                  return true;
                },
              }}
            />
          )}
          <div className="tw-flex tw-gap-2">
            <FormGroup className="tw-flex-1">
              <FormLabel htmlFor="pricePerShare">
                {i18n.t("restrictionCase.pricePerShare")}
              </FormLabel>
              <Input
                id="pricePerShare"
                type="number"
                step={0.01}
                {...register("pricePerShare", {
                  required: i18n.t("error.validation.required"),
                  valueAsNumber: true,
                  min: {
                    value: 1,
                    message: i18n.t("error.validation.range.min", { min: 1 }),
                  },
                  max: {
                    value: maxShares,
                    message: i18n.t("error.validation.range.max", {
                      min: maxShares,
                    }),
                  },
                })}
              />
              <FormError>{errors.pricePerShare?.message}</FormError>
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="currency">
                {i18n.t("label.currency")}
              </FormLabel>
              <Controller
                name="currency"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <>
                    <SelectCurrency
                      value={value}
                      onChange={onChange}
                      menuPlacement="top"
                    />
                    <FormError>{fieldState.error?.message}</FormError>
                  </>
                )}
                rules={{ required: i18n.t("error.validation.required") }}
              />
            </FormGroup>
          </div>
          {mutation.error && <FormErrorList error={mutation.error} />}
        </form>
      </PageWrapper>
    </ProgressDialog>
  );
};

export { RestrictionCaseForm };
