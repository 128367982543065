import { ArrowsOutSimple } from "@phosphor-icons/react";
import { useState } from "react";

import { Description } from "../../components/design-system/Description";
import { ShareBlockDialog } from "../../components/ShareBlocks/Dialog";
import { ShareType } from "../../types/models/shares";
import { formatNumber } from "../../utils/format";

type ShareClassRowProps = {
  shareType: ShareType;
  total: number;
};

const ShareClassRow = ({ shareType, total }: ShareClassRowProps) => {
  const [open, setOpen] = useState(false);

  return (
    <button type="button" onClick={() => setOpen(true)}>
      <div className="tw-flex tw-h-full tw-justify-between tw-rounded tw-border tw-p-2 tw-text-left hover:tw-bg-neutral-50">
        <Description
          title={shareType.name}
          description={formatNumber(total)}
          descriptionWeight="medium"
          theme="grayBlack"
        />
        <ArrowsOutSimple className="tw-h-full" />
      </div>
      {open && (
        <ShareBlockDialog
          onClose={() => setOpen(false)}
          title={shareType.name}
          shares={total}
          votes={shareType.voteValue}
          conditions={shareType.condition}
        />
      )}
    </button>
  );
};

type ShareClassesProps = {
  shareClasses: ShareType[];
  shareTotals: Record<string, number>;
};

const ShareClasses = ({ shareClasses, shareTotals }: ShareClassesProps) => {
  const usedShareClasses = shareClasses.filter(
    (c) => shareTotals[c.name] !== undefined
  );

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-rounded-lg tw-border tw-border-neutral-200 tw-p-4">
      {usedShareClasses.map((c) => (
        <ShareClassRow
          key={c.name}
          shareType={c}
          total={shareTotals[c.name]!}
        />
      ))}
    </div>
  );
};

export { ShareClasses };
