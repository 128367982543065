import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useParentEventsQuery } from "../../../api/blockchain/events";
import { useUploadEventAttachmentsMutation } from "../../../api/rest/attachments";
import { useSession } from "../../../context/session";
import logo from "../../../pages/companies/[companyId]/bv.svg";
import IncreaseCapital from "../../../pages/CompanyShares/IncreaseCapital";
import { IssueShares } from "../../../pages/CompanyShares/IssueShares";
import PledgedSharesUpdate from "../../../pages/CompanyShares/PledgedSharesUpdate";
import Reclassification from "../../../pages/CompanyShares/Reclassification";
import ReduceCapital from "../../../pages/CompanyShares/ReduceCapital";
import ReverseSplitShares from "../../../pages/CompanyShares/ReverseSplitShares";
import ShareCertificateUpdate from "../../../pages/CompanyShares/ShareCertificatesUpdate";
import ShareTypeUpdate from "../../../pages/CompanyShares/ShareTypeUpdate";
import SplitShares from "../../../pages/CompanyShares/SplitShares";
import TransferShares from "../../../pages/CompanyShares/TransferShares";
import {
  useCompanyDataComparison,
  useEventNames,
} from "../../../pages/EventsValidation/EventsValidation.utils";
import {
  CompanyInformation,
  CompanyShareCapitalHistory,
} from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import { formatCurrency, formatNumber } from "../../../utils/format";
import { Attachment } from "../../design-system/Attachment";
import { Button } from "../../design-system/Button";
import {
  CertificateIcon,
  CrossIcon,
  IncreaseCapitalIcon,
  PledgedIcon,
  ReclassificationIcon,
  ReduceCapitalIcon,
  ShareClassUpdateIcon,
  ShareIssueIcon,
  SplitIcon,
  TransferIcon,
} from "../../design-system/icons";
import { TooltipV2 } from "../../design-system/Tooltip/TooltipV2";
import {
  AutofillValues,
  EventFormData,
  EventFormProps,
} from "../EventsWizard.utils";

type EventCardProps = {
  title: string;
  description: string;
  icon: ReactNode;
  onClick: () => void;
};

const EventCard = ({ title, description, icon, onClick }: EventCardProps) => {
  return (
    <button
      className="tw-flex tw-gap-4 tw-rounded tw-border tw-p-4"
      type="button"
      onClick={onClick}
    >
      {icon}
      <div className="tw-text-left">
        <h3 className="tw-text-sm tw-font-medium">{title}</h3>
        <p className="tw-text-wrap tw-text-sm tw-text-secondary">
          {description}
        </p>
      </div>
    </button>
  );
};

type EventAutofillCardProps = {
  event: CompanyShareCapitalHistory;
  enabled: boolean;
  onClick: () => void;
};

const EventAutofillCard = ({
  event,
  enabled,
  onClick,
}: EventAutofillCardProps) => {
  const i18n = useTranslation();
  const eventNames = useEventNames();

  const getDescription = () => {
    switch (event.type) {
      case "ShareIssue":
        return i18n.t("eventAutofill.event.description.shareIssue", {
          total: event.changes.shares && formatNumber(event.changes.shares),
          date: event.decisionDate,
          investment:
            event.payment && formatCurrency(event.payment, event.currency),
        });
      case "IncreaseCapitalBonusIssue":
        return i18n.t("eventAutofill.event.description.increaseCapital", {
          capital: formatCurrency(event.changes.capital, event.currency),
          date: event.decisionDate,
        });
      case "DecreaseCapital":
      case "DecreaseCapitalCancelShares":
        return i18n.t("eventAutofill.event.description.decreaseCapital", {
          capital: formatCurrency(event.changes.capital, event.currency),
          date: event.decisionDate,
        });
      default:
        return "";
    }
  };
  const description = getDescription();

  return (
    <div className="tw-flex tw-flex-col tw-items-start tw-items-center tw-gap-4 tw-rounded tw-bg-info-secondary tw-p-4 tw-text-info-primary md:tw-flex-row md:tw-justify-between">
      <div className="tw-flex tw-items-start tw-gap-4">
        <img src={logo} alt="bolagsverket-logo" />
        <div>
          <p className="tw-text-sm tw-font-medium">
            {i18n.t("eventAutofill.event.title", {
              type: eventNames[event.type],
            })}
          </p>
          <p className="tw-text-sm">{description}</p>
        </div>
      </div>
      {enabled ? (
        <Button
          color="primary"
          variant="solid"
          className="max-md:tw-w-full"
          onClick={onClick}
          size="md"
        >
          {i18n.t("eventsAutofill.event.button")}
        </Button>
      ) : (
        <TooltipV2
          content={i18n.t("eventsAutofill.event.button.disabled")}
          className="max-md:tw-w-full"
        >
          <Button disabled size="md" className="max-md:tw-w-full">
            {i18n.t("eventsAutofill.event.button")}
          </Button>
        </TooltipV2>
      )}
    </div>
  );
};

type SelectEventProps = {
  currentCompany: CompanyInformation | CompanyInvolvement;
  onSuccess: () => void;
  setFormData: Dispatch<SetStateAction<EventFormData>>;
  SelectedEvent?: (props: EventFormProps) => JSX.Element;
  setSelectedEvent: Dispatch<
    SetStateAction<((props: EventFormProps) => JSX.Element) | undefined>
  >;
};

const SelectEvent = ({
  onSuccess: success,
  setFormData,
  SelectedEvent,
  setSelectedEvent,
  currentCompany,
}: SelectEventProps) => {
  const i18n = useTranslation();
  const { user } = useSession();
  const [files, setFiles] = useState<File[]>([]);
  const [autofillData, setAutofillData] = useState<AutofillValues>();
  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const companyDataMismatch = useCompanyDataComparison(
    currentCompany.orgNumber,
    false
  );
  const missingEvents =
    companyDataMismatch
      ?.filter(
        (x) =>
          !x.ledger &&
          x.companyData &&
          x.companyData.type !== "CompanyFoundation"
      )
      .map((x) => x.companyData!)
      .reverse() || [];

  const getMissingEventPage = (event: CompanyShareCapitalHistory) => {
    switch (event.type) {
      case "ShareIssue":
        return IssueShares;
      case "IncreaseCapitalBonusIssue":
        return IncreaseCapital;
      case "DecreaseCapital":
      case "DecreaseCapitalCancelShares":
        return ReduceCapital;
      default:
        return null;
    }
  };

  const uploadFilesMutation = useUploadEventAttachmentsMutation(
    currentCompany.orgNumber,
    { onSuccess: success }
  );

  useEffect(() => {
    setFormData((d) => ({
      ...d,
      loading: uploadFilesMutation.isLoading,
    }));
  }, [uploadFilesMutation.isLoading, setFormData]);

  const onSuccess = (eventId: string) => {
    eventsQuery.refetch();
    setFiles((f) => {
      if (f.length > 0) {
        uploadFilesMutation.mutate({ files: f, eventId, userId: user!.id });
      } else {
        success();
      }
      return f;
    });
  };

  const events = [
    {
      type: "transfer",
      title: i18n.t("events.transfer.title"),
      description: i18n.t("events.transfer.description"),
      icon: TransferIcon,
      formId: "transfer-shares-form",
      primary: true,
      page: TransferShares,
    },
    {
      type: "issueshares",
      title: i18n.t("events.issue.title"),
      description: i18n.t("events.issue.description"),
      icon: ShareIssueIcon,
      primary: true,
      page: IssueShares,
    },
    {
      type: "split",
      title: i18n.t("events.split.title"),
      description: i18n.t("events.split.description"),
      icon: SplitIcon,
      primary: true,
      page: SplitShares,
    },
    {
      type: "reversesplit",
      title: i18n.t("events.reverseSplit.title"),
      description: i18n.t("events.reverseSplit.description"),
      icon: CrossIcon,
      page: ReverseSplitShares,
    },
    {
      type: "reducecapital",
      title: i18n.t("events.reduceCapital.title"),
      description: i18n.t("events.reduceCapital.description"),
      icon: ReduceCapitalIcon,
      page: ReduceCapital,
    },
    {
      type: "increasecapital",
      title: i18n.t("events.increaseCapital.title"),
      description: i18n.t("events.increaseCapital.description"),
      icon: IncreaseCapitalIcon,
      page: IncreaseCapital,
    },
    {
      type: "reclassification",
      title: i18n.t("events.reclassification.title"),
      description: i18n.t("events.reclassification.description"),
      icon: ReclassificationIcon,
      page: Reclassification,
    },
    {
      type: "sharetypeupdate",
      title: i18n.t("events.shareTypeUpdate.title"),
      description: i18n.t("events.shareTypeUpdate.description"),
      icon: ShareClassUpdateIcon,
      page: ShareTypeUpdate,
    },
    {
      type: "sharecertificateupdate",
      title: i18n.t("events.shareCertificateUpdate.title"),
      description: i18n.t("events.shareCertificateUpdate.description"),
      icon: CertificateIcon,
      page: ShareCertificateUpdate,
    },
    {
      type: "pledgedsharesupdate",
      title: i18n.t("events.pledgedSharesUpdate.title"),
      description: i18n.t("events.pledgedSharesUpdate.description"),
      icon: PledgedIcon,
      page: PledgedSharesUpdate,
    },
  ];

  if (SelectedEvent) {
    return (
      <div className="tw-flex tw-flex-col tw-gap-6 tw-pb-4 max-md:tw-p-4">
        <SelectedEvent
          currentCompany={currentCompany}
          onSuccess={onSuccess}
          setFormData={setFormData}
          autofillData={autofillData}
        />
        <Attachment files={files} setFiles={setFiles} />
      </div>
    );
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-pb-4 max-md:tw-p-4">
      <h1 className="tw-text-2xl tw-font-medium">{i18n.t("events.add")}</h1>
      <div className="tw-flex tw-flex-col tw-gap-2">
        {missingEvents.map((e, i) => {
          const eventPage = getMissingEventPage(e);
          if (!eventPage) {
            throw Error("Invalid event autofill page");
          }
          return (
            <EventAutofillCard
              key={e.id}
              event={e}
              enabled={i === 0}
              onClick={() => {
                setSelectedEvent(() => eventPage);
                setAutofillData({
                  date: e.decisionDate,
                  shares: e.changes.shares,
                  capital: e.changes.capital,
                  payment: e.payment,
                });
              }}
            />
          );
        })}
      </div>
      <div className="tw-grid tw-grid-cols-1 tw-gap-2 md:tw-grid-cols-2">
        {events.map((e) => (
          <EventCard
            key={e.type}
            title={e.title}
            description={e.description}
            icon={<e.icon className="tw-h-6 tw-w-6" />}
            onClick={() => {
              setSelectedEvent(() => e.page);
              setAutofillData(undefined);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export { SelectEvent };
