import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { LedgerVersionDetails } from "../../pages/CompanyShares/useLedgerVersions";
import { ViewMenu } from "../../pages/ViewSharing/ViewMenu";
import { Button } from "../design-system/Button";
import { notify } from "../design-system/Notifications";

const ShareView = ({
  orgNumber,
  selectedVersion,
}: {
  orgNumber: string;
  selectedVersion?: LedgerVersionDetails;
}) => {
  const i18n = useTranslation();

  return (
    <ViewMenu
      title={i18n.t("views.create.title")}
      defaultValues={
        selectedVersion
          ? { shareLedgerVersion: selectedVersion.formatedValue }
          : undefined
      }
      onSuccess={() => {
        notify(
          <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-4">
            <p className="tw-text-sm tw-font-medium">
              {i18n.t("shareholders.share.success.title")}
            </p>
            <Link to={`/companies/${orgNumber}/views`}>
              <Button size="md" className="tw-font-medium">
                {i18n.t("shareholders.share.success.link")}
              </Button>
            </Link>
          </div>,
          { type: "success" }
        );
      }}
      orgNumber={orgNumber}
    />
  );
};

export { ShareView };
