import { useTranslation } from "react-i18next";

import type { LedgerVersionDetails } from "../../../pages/CompanyShares/useLedgerVersions";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import { EntitiesMap } from "../../../types/models/entities";
import { RestrictionCase } from "../../../types/models/restriction-case";
import {
  Shareblock,
  ShareBlockHistory,
  ShareTypesMap,
} from "../../../types/models/shares";
import { formatRefId } from "../../../utils/format";
import { PageHeader } from "../../design-system/PageHeader/PageHeader";
import { ExportMenuShareledger } from "../../ExportMenu";
import { SelectVersion } from "../../SelectVersion";

type ShareLedgerHeaderProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  ledgerVersions: LedgerVersionDetails[];
  selectedVersion?: LedgerVersionDetails;
  setSelectedVersion: (version?: LedgerVersionDetails) => void;
  sortedBlocks: Shareblock[];
  entitiesMap: EntitiesMap;
  shareTypesMap: ShareTypesMap;
  shareBlockHistory: ShareBlockHistory[];
  notes: Record<string, RestrictionCase[]>;
};

const ShareLedgerHeader: React.FC<ShareLedgerHeaderProps> = ({
  currentCompany,
  ledgerVersions,
  selectedVersion,
  setSelectedVersion,
  sortedBlocks,
  entitiesMap,
  shareTypesMap,
  shareBlockHistory,
  notes,
}) => {
  const i18n = useTranslation();

  return (
    <div className="max-md:tw-hidden">
      <PageHeader
        title={`${currentCompany.name} ${i18n.t("label.shareLedger")}`}
        description={formatRefId({
          refId: currentCompany.orgNumber,
          countryCode: currentCompany.countryCode,
          type: "Company",
        })}
        actions={[
          <ExportMenuShareledger
            key="export"
            currentCompany={currentCompany}
            ledgerVersions={ledgerVersions}
            selectedVersion={selectedVersion}
            shareblocks={sortedBlocks}
            entitiesMap={entitiesMap}
            shareTypesMap={shareTypesMap}
            shareBlockHistory={shareBlockHistory}
            notes={notes}
            menuItemsAlignment="right"
          />,
          <SelectVersion
            key="version"
            availableVersions={ledgerVersions}
            selectedVersion={selectedVersion}
            onChange={setSelectedVersion}
          />,
        ]}
      />
    </div>
  );
};

export { ShareLedgerHeader };
