import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import VerificationInput from "react-verification-input";

import { TOO_MANY_REQUESTS } from "../../api/rest/utils";
import { useVerifyCodeMutation } from "../../api/rest/views";
import { Alert } from "../../components/design-system/Alert";
import { Loading } from "../../components/design-system/Loading";
import { PublicPageLayout } from "../../components/PublicPageLayout";
import { Token } from "../../types/models/views";
import { clsxm } from "../../utils/tailwind";

type ExternalViewEmailVerificationProps = {
  orgNumber: string;
  viewId: string;
  encodedEmail: string;
  onSuccess: (data: Token) => void;
  newCode: () => void;
};

const ExternalViewEmailVerification = ({
  orgNumber,
  viewId,
  encodedEmail,
  onSuccess,
  newCode,
}: ExternalViewEmailVerificationProps) => {
  const i18n = useTranslation();
  const [code, setCode] = useState("");
  const verifyCodeMutation = useVerifyCodeMutation({
    onSuccess: (data) => {
      onSuccess(data.token);
    },
  });

  if (verifyCodeMutation.isLoading) {
    return <Loading />;
  }

  return (
    <PublicPageLayout>
      <div className="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-text-center">
        <h1>{i18n.t("externalView.verification.title")}</h1>
        <p>{i18n.t("externalView.verification.description")}</p>
        <VerificationInput
          value={code}
          onChange={setCode}
          onComplete={(c) =>
            verifyCodeMutation.mutate({
              orgNumber,
              viewId,
              email: encodedEmail,
              code: c,
            })
          }
          length={6}
          classNames={{
            container: clsxm("tw-mx-auto", {
              "tw-text-error": verifyCodeMutation.error,
            }),
            character: clsxm({
              "tw-border-error tw-text-error": verifyCodeMutation.error,
            }),
          }}
          placeholder="_"
          validChars="0-9"
          inputProps={{
            type: "number",
            pattern: "[0-9]*",
            inputMode: "numeric",
          }}
        />
        {verifyCodeMutation.error && (
          <Alert type="error" className="tw-justify-center">
            {verifyCodeMutation.error.status === TOO_MANY_REQUESTS
              ? i18n.t("externalView.verification.error.tooMany")
              : i18n.t("externalView.verification.error.code")}
          </Alert>
        )}
        <div className="tw-flex tw-justify-center tw-gap-2">
          <Trans
            components={{
              retryLink: (
                <button
                  onClick={() => newCode()}
                  className="tw-underline"
                  type="button"
                  aria-label="Re-verify"
                />
              ),
            }}
            i18nKey="externalView.verification.link"
          />
        </div>
      </div>
    </PublicPageLayout>
  );
};

export { ExternalViewEmailVerification };
