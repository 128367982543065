import { formatNumber } from "../../../utils/format";
import { calcSumWithinRange } from "../../../utils/shares";
import { Badge } from "../Badge";
import { Description } from "../Description";
import { EntityItem } from "../EntityItem";
import { Option as SelectOption, OptionProps } from "../Select";
import { ShareBlock } from "./SelectRange";

type IOptionProps = OptionProps<ShareBlock, true>;

const Option = (props: IOptionProps) => {
  const { data: block } = props;

  return (
    <SelectOption {...props}>
      <div className="tw-grid tw-grid-cols-1 tw-gap-4 lg:tw-grid-cols-3">
        <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
          <Description
            description={`${formatNumber(calcSumWithinRange(block))}`}
            title={`${formatNumber(block.start)} - ${formatNumber(block.end)}`}
          />
          <div className="tw-flex tw-items-center">
            <Badge>{block.type}</Badge>
          </div>
        </div>
        <EntityItem value={block.entity} />
      </div>
    </SelectOption>
  );
};

export { Option };
