import { UseTranslationResponse } from "react-i18next";

import {
  ledgerDocumentDownload,
  shareholdersDocumentDownload,
} from "../../api/rest/company";
import { getShareHoldersWithDilution } from "../../pages/CompanyShares/ShareHolders.utils";
import { CompanyInformation } from "../../types/models/administration";
import { CompanyInvolvement } from "../../types/models/company";
import { EntitiesMap, Entity } from "../../types/models/entities";
import { OptionsProgram } from "../../types/models/options";
import { RestrictionCase } from "../../types/models/restriction-case";
import {
  LedgerVersion,
  Shareblock,
  ShareBlockHistory,
  Shareholder,
  ShareTypeClause,
  ShareTypesMap,
} from "../../types/models/shares";
import { downloadBlob } from "../../utils/download";
import {
  generateNotesData,
  generateShareholderData,
  generateShareledgerData,
  ShareholderDataTotals,
} from "../../utils/excel-utils";
import * as monitoring from "../../utils/monitoring";
import {
  createWorksheet,
  exportToExcel,
  generateExcel,
} from "../../utils/xlsx";

const downloadShareholdersPdf = async (
  suffix: string,
  currentCompany: CompanyInvolvement | CompanyInformation,
  version: LedgerVersion,
  optionProgramIds: string[],
  language: string
) => {
  const response = await shareholdersDocumentDownload(
    currentCompany.orgNumber,
    language,
    version,
    optionProgramIds
  );
  if (response.status === 200) {
    const blob = await response.blob();
    downloadBlob(
      blob,
      `${suffix}_${currentCompany.name}_${currentCompany.orgNumber}_${version}.pdf`
    );
  } else {
    console.error(response);
    monitoring.captureException(
      new Error("Error downloading shareholders document"),
      {
        extra: {
          status: response.status,
          text: response.statusText,
          company: currentCompany.orgNumber,
          version,
        },
      }
    );
  }
};

const downloadShareledgerPdf = async (
  suffix: string,
  currentCompany: CompanyInvolvement | CompanyInformation,
  version: LedgerVersion,
  language: string
) => {
  const response = await ledgerDocumentDownload(
    currentCompany.orgNumber,
    language,
    version
  );
  if (response.status === 200) {
    const blob = await response.blob();
    downloadBlob(
      blob,
      `${suffix}_${currentCompany.name}_${currentCompany.orgNumber}_${version}.pdf`
    );
  } else {
    console.error(response);
    monitoring.captureException(
      new Error("Error downloading shareledger document"),
      {
        extra: {
          status: response.status,
          text: response.statusText,
          company: currentCompany.orgNumber,
          version,
        },
      }
    );
  }
};

const downloadShareholdersXlsx = (
  shareholders: (Shareholder & { entity: Entity })[],
  selectedOptionPrograms: OptionsProgram[],
  shareTypesByName: Record<string, number>,
  entities: Record<string, Entity>,
  totals: ShareholderDataTotals,
  currentCompany: CompanyInvolvement | CompanyInformation,
  version: LedgerVersion,
  i18n: UseTranslationResponse<"translation">,
  lng: string
) => {
  const { shareHoldersWithDilution } = getShareHoldersWithDilution(
    shareholders,
    selectedOptionPrograms,
    shareTypesByName,
    entities,
    []
  );
  const data = generateShareholderData(
    shareHoldersWithDilution,
    selectedOptionPrograms.length > 0,
    totals,
    i18n,
    lng
  );

  const [date, subversion] = version.split(".") || ".";
  const versionLabel = `${date} v${subversion}`;

  exportToExcel(
    data,
    [i18n.t("label.shares", { lng }), i18n.t("label.votes", { lng })],
    `${currentCompany.name} - ${currentCompany.orgNumber} - ${i18n.t(
      "label.shareholders",
      { lng }
    )} - ${versionLabel} - ${i18n.t("label.downloaded", {
      lng,
      date: new Date().toISOString().split("T")[0],
    })}`,
    `${i18n.t("label.shareholders", { lng })} ${versionLabel}`
  );
};

const downloadShareledgerXlsx = (
  shareblocks: Shareblock[],
  notes: Record<string, RestrictionCase[]>,
  entitiesMap: EntitiesMap,
  shareTypesMap: ShareTypesMap,
  shareBlockHistory: ShareBlockHistory[],
  conditionOptions: {
    value: ShareTypeClause;
    label: string;
  }[],
  currentCompany: CompanyInvolvement | CompanyInformation,
  version: LedgerVersion,
  i18n: UseTranslationResponse<"translation">,
  lng: string
) => {
  const allNotes = Array.from(new Set(Object.values(notes).flat())).sort(
    (a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1)
  );
  const shareholderToNote = Object.entries(notes).reduce(
    (prev, [id, n]) => ({ ...prev, [id]: allNotes.indexOf(n[0]!) }),
    {} as Record<string, number>
  );
  const data = generateShareledgerData(
    shareblocks,
    shareholderToNote,
    entitiesMap,
    shareTypesMap,
    shareBlockHistory,
    conditionOptions,
    i18n,
    lng
  );
  const notesData = generateNotesData(allNotes, entitiesMap, i18n, lng);

  const [date, subversion] = version.split(".") || ".";
  const versionLabel = `${date} v${subversion}`;

  const mainWs = createWorksheet(data, [
    i18n.t("label.shares", { lng }),
    i18n.t("label.votes", { lng }),
  ]);
  const notesWs = createWorksheet(notesData);
  generateExcel(
    `${currentCompany.name} - ${currentCompany.orgNumber} - ${i18n.t(
      "label.shareLedger",
      { lng }
    )} - ${versionLabel} - ${i18n.t("label.downloaded", {
      lng,
      date: new Date().toISOString().split("T")[0],
    })}`,
    {
      [`${i18n.t("label.shareLedger", { lng })} ${versionLabel}`]: mainWs,
      ...(allNotes.length > 0 && {
        [i18n.t("restrictionCase.title", { lng })]: notesWs,
      }),
    }
  );
};

export {
  downloadShareholdersPdf,
  downloadShareholdersXlsx,
  downloadShareledgerPdf,
  downloadShareledgerXlsx,
};
