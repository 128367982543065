import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Input } from "../../../components/design-system/Input";
import { Select } from "../../../components/design-system/Select";
import { formatCurrency } from "../../../utils/format";

type CapitalAmountProps = {
  amount?: number;
  currency?: string;
  totalShares?: number;
  onChange: (value: number) => void;
};

const CapitalAmount = ({
  amount,
  currency,
  totalShares,
  onChange,
}: CapitalAmountProps) => {
  const i18n = useTranslation();
  const [capitalAmount, setCapitalAmount] = useState<number>();
  const [method, setMethod] = useState<"total" | "perShare">("total");

  useEffect(() => {
    if (capitalAmount !== undefined) {
      const value =
        method === "perShare"
          ? (totalShares || 1) * capitalAmount
          : capitalAmount;
      onChange(value);
    }
  }, [capitalAmount, method, totalShares]);

  const investmentOptions = [
    { label: i18n.t("events.issue.investmentAmount.total"), value: "total" },
    ...(totalShares
      ? [
          {
            label: i18n.t("events.issue.investmentAmount.perShare"),
            value: "perShare",
          },
        ]
      : []),
  ];

  return (
    <div className="tw-flex tw-gap-2">
      <div className="tw-flex-1 tw-space-y-2">
        <Input
          id="investmentAmount"
          data-testid="investmentAmount"
          type="number"
          step={0.000001}
          prefix={currency}
          value={
            method === "perShare" ? (amount || 0) / (totalShares || 1) : amount
          }
          onChange={(e) => setCapitalAmount(e.target.valueAsNumber)}
        />
        {amount && totalShares ? (
          <p className="tw-text-sm tw-text-secondary">
            {method === "total"
              ? `${i18n.t(
                  "events.issue.investment.perShare"
                )}: ${formatCurrency(amount / totalShares, currency)}`
              : `${i18n.t("events.issue.investment.total")}: ${formatCurrency(
                  amount,
                  currency,
                  0,
                  0
                )}`}
          </p>
        ) : null}
      </div>
      <Select
        id="investmentMethod"
        value={investmentOptions.find((v) => v.value === method)}
        onChange={(e) => {
          if (e?.value) {
            setMethod(e.value as "total" | "perShare");
          }
        }}
        options={investmentOptions}
        className="tw-flex-1"
      />
    </div>
  );
};

export { CapitalAmount };
