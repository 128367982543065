import { DownloadSimple } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { LedgerVersionDetails } from "../../pages/CompanyShares/useLedgerVersions";
import { OptionsProgram } from "../../types/models/options";
import { clsxm } from "../../utils/tailwind";
import { Button } from "../design-system/Button";
import { FormError, FormGroup, FormLabel } from "../design-system/FormGroup";
import { Menu } from "../design-system/Menu";
import { MenuItemsAlignment, Select } from "../design-system/Select";
import { SelectVersion } from "../SelectVersion";

type FormData = {
  version?: LedgerVersionDetails;
  type: ExportType;
  language: string;
  selectedPrograms: string[] | undefined;
};

type ExportMenuProps = {
  ledgerVersions: LedgerVersionDetails[];
  downloadPdf: (data: FormData) => Promise<void>;
  downloadXlsx: (data: FormData) => void;
  selectedVersion?: LedgerVersionDetails;
  optionPrograms?: OptionsProgram[];
  selectedPrograms?: string[];
  menuItemsAlignment?: MenuItemsAlignment;
};

type ExportType = "pdf" | "xlsx";

const ExportMenu = ({
  ledgerVersions,
  downloadPdf,
  downloadXlsx,
  selectedVersion,
  optionPrograms,
  selectedPrograms,
  menuItemsAlignment,
}: ExportMenuProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const i18n = useTranslation();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const exportTypeOptions: {
    label: string;
    value: ExportType;
  }[] = [
    { label: i18n.t("exportMenu.type.pdf"), value: "pdf" },
    { label: i18n.t("exportMenu.type.xlsx"), value: "xlsx" },
  ];

  const languageOptions = [
    { label: i18n.t("language.swedish"), value: "sv-SE" },
    { label: i18n.t("language.english"), value: "en-US" },
  ];

  const { control, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      version: selectedVersion,
      type: "pdf" as ExportType,
      language: i18n.i18n.language,
      selectedPrograms,
    },
  });

  useEffect(() => {
    setValue("version", selectedVersion);
  }, [selectedVersion]);

  useEffect(() => {
    setValue("selectedPrograms", selectedPrograms);
  }, [selectedPrograms]);

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    if (data.type === "pdf") {
      await downloadPdf(data);
    } else {
      downloadXlsx(data);
    }
    setIsLoading(false);
  };

  return (
    <Menu className="tw-w-fit">
      <Menu.Button
        type="button"
        className="tw-flex tw-items-center tw-p-4 max-md:tw-p-3"
        aria-label="Export"
        isDropdown={false}
      >
        <DownloadSimple className="tw-h-6 tw-w-6" />
      </Menu.Button>
      <Menu.Items
        className={clsxm(
          "max-md:tw-mx-4 max-md:tw-w-[calc(100%-32px)] md:tw-min-w-[350px]",
          { "md:tw--ml-[calc(350px-58px)]": !isTabletOrMobileDevice }
        )}
        align={isTabletOrMobileDevice ? "topRight" : undefined}
      >
        <div className="tw-p-4">
          <form
            className="tw-space-y-4"
            onSubmit={(event) => handleSubmit((data) => onSubmit(data))(event)}
          >
            <FormGroup>
              <FormLabel htmlFor="version">{i18n.t("label.version")}</FormLabel>
              <Controller
                control={control}
                name="version"
                render={({ field: { onChange, value }, fieldState }) => (
                  <>
                    <SelectVersion
                      selectedVersion={value}
                      availableVersions={ledgerVersions}
                      onChange={onChange}
                      menuItemsAlignment={menuItemsAlignment}
                    />
                    <FormError>{fieldState.error?.message}</FormError>
                  </>
                )}
                rules={{ required: i18n.t("error.validation.required") }}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="type">{i18n.t("exportMenu.type")}</FormLabel>
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <>
                    <Select
                      name="type"
                      options={exportTypeOptions}
                      value={exportTypeOptions.find((o) => value === o.value)}
                      onChange={(t) => onChange(t?.value)}
                    />
                    <FormError>{fieldState.error?.message}</FormError>
                  </>
                )}
                name="type"
                rules={{ required: i18n.t("error.validation.required") }}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="language">
                {i18n.t("exportMenu.language")}
              </FormLabel>
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <>
                    <Select
                      name="language"
                      options={languageOptions}
                      value={languageOptions.find((o) => value === o.value)}
                      onChange={(l) => onChange(l?.value)}
                    />
                    <FormError>{fieldState.error?.message}</FormError>
                  </>
                )}
                name="language"
                rules={{ required: i18n.t("error.validation.required") }}
              />
            </FormGroup>
            {optionPrograms && optionPrograms.length > 0 && (
              <FormGroup>
                <FormLabel htmlFor="selectedPrograms" isOptional>
                  {i18n.t("exportMenu.programs")}
                </FormLabel>
                <Controller
                  control={control}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <>
                      <Select
                        name="selectedPrograms"
                        options={optionPrograms.map((p) => ({
                          label: p.title,
                          value: p.id!,
                        }))}
                        value={optionPrograms
                          .filter((p) => p.id && value!.includes(p.id))
                          .map((p) => ({ label: p.title, value: p.id! }))}
                        onChange={(programs) =>
                          onChange(programs.map((i) => i.value))
                        }
                        isMulti
                      />
                      <FormError>{fieldState.error?.message}</FormError>
                    </>
                  )}
                  name="selectedPrograms"
                />
              </FormGroup>
            )}
            <Button
              type="submit"
              color="primary"
              variant="solid"
              className="tw-w-full"
              prefix={<DownloadSimple />}
              isLoading={isLoading}
            >
              {i18n.t("exportMenu.button")}
            </Button>
          </form>
        </div>
      </Menu.Items>
    </Menu>
  );
};

export { ExportMenu };
export type { FormData };
