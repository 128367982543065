type PageHeaderProps = {
  title: string;
  description?: string;
  actions?: JSX.Element[];
};

const PageHeader = ({ title, description, actions }: PageHeaderProps) => (
  <header className="tw-w-full">
    <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-between tw-gap-4 tw-pb-4 md:tw-flex-row md:tw-pb-6">
      <div className="tw-max-w-[650px]">
        <h1 className="tw-whitespace-nowrap tw-text-2xl tw-font-medium">
          {title}
        </h1>
        <p className="tw-text-sm tw-text-secondary">{description}</p>
      </div>
      <div className="tw-flex tw-w-full tw-flex-1 tw-flex-col tw-justify-end tw-gap-2 md:tw-flex-row">
        {actions}
      </div>
    </div>
    <hr className="tw-absolute tw-left-[-24px] tw-right-[-24px]" />
  </header>
);

export { PageHeader };
