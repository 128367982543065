import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useParentEventsQuery } from "../../api/blockchain/events";
import { useEntitiesQuery } from "../../api/rest/entities";
import { useSharedViewsQuery } from "../../api/rest/views";
import { Loading } from "../../components/design-system/Loading";
import { PageHeader } from "../../components/design-system/PageHeader/PageHeader";
import { Tab } from "../../components/design-system/Tab";
import { PageWrapper } from "../../components/PageWrapper";
import { useCurrentCompany } from "../../context/account";
import { useSession } from "../../context/session";
import { filterEvents } from "../../hooks/useCompanyEvents/filter-events";
import { hasRequiredPermission } from "../../utils/permissions";
import { ViewDialog } from "./ViewDialog";
import { ViewMenu } from "./ViewMenu";
import { ViewsTable } from "./ViewsTables";

const ViewSharingPage = () => {
  const i18n = useTranslation();
  const [sortBy, setSortBy] = useState("created-desc");
  const [editView, setEditView] = useState("");
  const currentCompany = useCurrentCompany();
  const viewsQuery = useSharedViewsQuery(currentCompany?.orgNumber);
  const views = viewsQuery.data || [];
  const { user } = useSession();
  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany?.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const events = filterEvents(eventsQuery.data?.data || []);
  const entitiesQuery = useEntitiesQuery(currentCompany?.orgNumber);
  const entities = entitiesQuery.data || [];

  if (!user || !currentCompany) {
    return null;
  }

  if (
    viewsQuery.isLoading ||
    eventsQuery.isLoading ||
    entitiesQuery.isLoading
  ) {
    return <Loading />;
  }

  const canEdit = hasRequiredPermission("Administrator", currentCompany, user);
  const now = new Date();

  return (
    <PageWrapper>
      <PageHeader
        title={i18n.t("label.views")}
        description={i18n.t("views.description")}
        actions={[
          ...(canEdit
            ? [
                <ViewMenu
                  key="add"
                  title={i18n.t("views.create.title")}
                  orgNumber={currentCompany.orgNumber}
                  menuItemsAlignment="right"
                />,
              ]
            : []),
        ]}
      />
      <Tab.Group>
        <Tab.List className="tw-py-4">
          <Tab>{i18n.t("views.tab.active")}</Tab>
          <Tab
            disabled={
              views.filter((view) => new Date(view.expiryDate) < now).length ===
              0
            }
          >
            {i18n.t("views.tab.inactive")}
          </Tab>
        </Tab.List>
        <Tab.Panel>
          <ViewsTable
            views={views.filter((view) => new Date(view.expiryDate) >= now)}
            entities={entities}
            events={events}
            sortBy={sortBy}
            setSortBy={setSortBy}
            setEditView={setEditView}
          />
        </Tab.Panel>
        <Tab.Panel>
          <ViewsTable
            views={views.filter((view) => new Date(view.expiryDate) < now)}
            entities={entities}
            events={events}
            sortBy={sortBy}
            setSortBy={setSortBy}
            setEditView={setEditView}
          />
        </Tab.Panel>
      </Tab.Group>
      {editView && (
        <ViewDialog
          onClose={() => setEditView("")}
          onSuccess={() => setEditView("")}
          orgNumber={currentCompany.orgNumber}
          defaultValues={views.find((x) => x.viewId === editView)}
        />
      )}
    </PageWrapper>
  );
};

export { ViewSharingPage };
