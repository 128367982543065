import { Plus } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { useCompaniesQuery } from "../../../api/blockchain/company";
import { useCurrentCompany } from "../../../context/account";
import { useSession } from "../../../context/session";
import { CompanyBadge } from "../../../pages/ledgers";
import { roleLabels } from "../../../types/models/users";
import { getRole } from "../../../utils/permissions";
import { clsxm } from "../../../utils/tailwind";
import { Menu } from "../Menu";

const CompanySwitcher = ({
  onClick,
  showAddCompany,
}: {
  onClick?: () => void;
  showAddCompany: () => void;
}) => {
  const i18n = useTranslation();
  const { user } = useSession();
  const currentCompany = useCurrentCompany();
  const companiesQuery = useCompaniesQuery();
  const location = useLocation();
  const companies = (companiesQuery.data || []).sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  if (!currentCompany) {
    return null;
  }

  const role = getRole(currentCompany, user);
  const getNewUrl = (companyId: string) => {
    const parts = location.pathname.split("/");
    parts[2] = companyId;
    if (parts.length > 4) {
      // Handle sub page navigation by redirecting to parent page
      return parts.slice(0, 4).join("/");
    }
    return parts.join("/");
  };

  return (
    <>
      <Menu className="tw-w-full tw-flex-1">
        <Menu.Button className="tw-w-full tw-flex-1 tw-justify-between tw-px-3 tw-text-left">
          <div>
            <div className="tw-max-w-[150px] tw-overflow-hidden tw-truncate tw-text-sm">
              {currentCompany?.name}
            </div>
            <div className="tw-text-sm tw-font-normal tw-text-secondary">
              {i18n.t(roleLabels[role])}
            </div>
          </div>
        </Menu.Button>
        <Menu.Items className="tw-w-[446px] tw-max-w-[calc(100vw-32px)]">
          <div className="tw-rounded">
            <div className="tw-max-h-[70vh] tw-overflow-y-scroll tw-border-b tw-p-2">
              {companies.map((c) => (
                <Link
                  to={getNewUrl(c.orgNumber)}
                  key={c.orgNumber}
                  onClick={onClick}
                >
                  <Menu.Item
                    className={clsxm(
                      "tw-flex tw-items-center tw-justify-between tw-gap-2 tw-p-2 hover:tw-bg-neutral-100",
                      {
                        "tw-bg-neutral-50":
                          c.orgNumber === currentCompany.orgNumber,
                      }
                    )}
                  >
                    <div className="tw-block">
                      <div className="tw-max-w-[180px] tw-overflow-hidden tw-truncate tw-text-sm tw-font-medium">
                        {c.name}
                      </div>
                      <div className="tw-text-sm tw-font-normal tw-text-secondary">
                        {i18n.t(roleLabels[c.access])}
                      </div>
                    </div>
                    <div className="max-sm:tw-hidden">
                      <CompanyBadge company={c} />
                    </div>
                  </Menu.Item>
                </Link>
              ))}
              <Menu.Item
                className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-p-2 tw-text-sm tw-font-medium hover:tw-bg-neutral-100"
                onClick={() => {
                  if (onClick) {
                    onClick();
                  }
                  showAddCompany();
                }}
              >
                <Plus />
                {i18n.t("addCompany.button")}
              </Menu.Item>
            </div>
          </div>
        </Menu.Items>
      </Menu>
    </>
  );
};

export { CompanySwitcher };
