import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/design-system/Button";
import { Dialog } from "../../components/design-system/Dialog";
import { ViewForm } from "./ViewForm";

type CreateViewDialogProps = {
  orgNumber: string;
  onClose: () => void;
  onSuccess: () => void;
  defaultValues?: {
    viewId?: string;
    shareLedgerVersion: string;
    expiryDate?: string;
    sharedEmails?: { email: string; invitationSent: boolean }[];
  };
};

const formId = "create-view-form";

const ViewDialog = ({
  orgNumber,
  onClose,
  onSuccess,
  defaultValues,
}: CreateViewDialogProps) => {
  const i18n = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      title={i18n.t("views.create.title")}
      isOpen
      onClose={onClose}
      canClose={!loading}
      actions={
        <Button
          className="tw-flex-1"
          variant="solid"
          color="primary"
          type="submit"
          form={formId}
          isLoading={loading}
        >
          {i18n.t(
            defaultValues?.viewId ? "views.edit.submit" : "views.create.submit"
          )}
        </Button>
      }
    >
      <p className="tw-pb-4 tw-text-sm tw-text-secondary">
        {i18n.t("views.create.description")}
      </p>
      <ViewForm
        orgNumber={orgNumber}
        onSuccess={() => {
          onSuccess();
          onClose();
        }}
        defaultValues={defaultValues}
        setLoading={setLoading}
      />
    </Dialog>
  );
};

export { ViewDialog };
