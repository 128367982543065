import {
  Control,
  Controller,
  useFieldArray,
  UseFormRegister,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "../../../components/design-system/Button";
import {
  FormError,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { PlusIcon, TrashIcon } from "../../../components/design-system/icons";
import { Select } from "../../../components/design-system/Select";
import {
  SelectRange,
  ShareBlock,
  ShareRangeError,
} from "../../../components/design-system/SelectRange";
import {
  ShareOwnership,
  SharesTransferForm,
  Transfer,
} from "../../../types/models/shares";
import { formatNumber } from "../../../utils/format";
import { TransferErrors } from "./TransferForm";

type TransferRangeRowProps = TransferRangeProps & {
  index: number;
  remove: () => void;
};

const TransferRangeRow = ({
  id,
  index,
  allValues,
  shareTypes,
  shareBlocks,
  sender,
  register,
  control,
  errors,
  remove,
}: TransferRangeRowProps) => {
  const i18n = useTranslation();
  const shareType = useWatch({
    control,
    name: `transfers.${id}.transfers.${index}.shareType`,
  });
  const otherSelectShareTypes = allValues
    .map((t) => t.shareType)
    .filter((c, i) => c !== undefined && index !== i);
  const availableShareTypes = shareTypes.filter(
    (s) => !otherSelectShareTypes.includes(s.type)
  );
  const applicableBlocks = shareBlocks.filter(
    (b) => b.type === shareType && b.entity.id === sender
  );

  return (
    <div className="tw-space-y-6 tw-rounded tw-border tw-p-4">
      <Controller
        control={control}
        render={({ field: { onChange: change, value: v }, fieldState }) => (
          <FormGroup>
            <FormLabel htmlFor={`transfers.${id}.transfers.${index}.shareType`}>
              {i18n.t("label.shareType")}
            </FormLabel>
            <Select
              options={availableShareTypes}
              getOptionLabel={(option) =>
                `${option.type} (${formatNumber(option.amount)})`
              }
              getOptionValue={(option) => option.type}
              value={shareTypes.find(({ type }) => type === v)}
              onChange={(newValue) => change(newValue?.type)}
              isDisabled={!sender}
            />
            <FormError>{fieldState.error?.message}</FormError>
          </FormGroup>
        )}
        rules={{
          required: i18n.t("error.validation.required"),
        }}
        name={`transfers.${id}.transfers.${index}.shareType`}
      />
      <Controller
        control={control}
        render={({ field: { value, onChange: change }, fieldState }) => (
          <FormGroup>
            <SelectRange
              allShareBlocks={applicableBlocks}
              value={value}
              fieldKey={`transfers.${id}.transfers.${index}.shareRanges`}
              onChange={(data) => change(data)}
              register={register}
              errors={
                errors &&
                errors[id] &&
                errors[id].transfers &&
                (errors[id].transfers[index]?.shareRanges as ShareRangeError[])
              }
              isDisabled={!sender || !shareType}
            />
            <FormError>{fieldState.error?.message}</FormError>
          </FormGroup>
        )}
        rules={{
          required: i18n.t("error.validation.required"),
        }}
        name={`transfers.${id}.transfers.${index}.shareRanges`}
      />
      <Button
        onClick={() => remove()}
        className="tw-w-full"
        prefix={<TrashIcon />}
      >
        {i18n.t("events.transfer.transfers.remove")}
      </Button>
    </div>
  );
};

type TransferRangeProps = {
  id: string;
  allValues: Transfer[];
  shareTypes: ShareOwnership[];
  shareBlocks: ShareBlock[];
  sender?: string;
  register: UseFormRegister<SharesTransferForm>;
  control: Control<SharesTransferForm>;
  errors?: TransferErrors;
};

const TransferRange = (props: TransferRangeProps) => {
  const i18n = useTranslation();
  const { fields, remove, append } = useFieldArray({
    control: props.control,
    name: `transfers.${props.id}.transfers`,
  });

  return (
    <div className="tw-space-y-6">
      <Controller
        control={props.control}
        name={`transfers.${props.id}.transfers`}
        rules={{ required: i18n.t("error.validation.required") }}
        render={() => (
          <>
            {fields.map((value, index) => (
              <TransferRangeRow
                {...props}
                key={value.id}
                index={index}
                remove={() => remove(index)}
              />
            ))}
          </>
        )}
      />
      <Button
        prefix={<PlusIcon />}
        onClick={() =>
          append({
            sharesAmount: undefined,
            shareType: undefined,
            shareRanges: [],
          })
        }
        className="tw-w-full"
      >
        {i18n.t("events.transfer.transfers.addClass")}
      </Button>
    </div>
  );
};

export { TransferRange };
