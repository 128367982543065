import type React from "react";
import { useTranslation } from "react-i18next";

import type { Condition } from "../../../types/models/shares";
import { formatNumber } from "../../../utils/format";
import { Description } from "../../design-system/Description";
import { Dialog } from "../../design-system/Dialog";
import { InfoIcon } from "../../design-system/icons";
import { TooltipV2 } from "../../design-system/Tooltip/TooltipV2";
import { useRestrictiveConditionOptions } from "../../ShareTypes/SelectRestrictiveConditions";

type DialogProps = {
  onClose: () => void;
  title: string;
  shares: number;
  votes: number;
  conditions: Condition;
};

const ShareBlockDialog: React.FC<DialogProps> = ({
  onClose,
  title,
  shares,
  votes,
  conditions,
}) => {
  const { t } = useTranslation();
  const conditionOptions = useRestrictiveConditionOptions();
  const hasTrueConditions = Object.values(conditions).some((value) => value);

  return (
    <Dialog title={title} isOpen onClose={onClose}>
      <div className="tw-flex tw-flex-col tw-gap-6 tw-py-2">
        <Description
          title={t("label.numberOfShares")}
          description={formatNumber(shares)}
          theme="grayBlack"
        />
        <Description
          title={t("label.numberOfVotesPerShare")}
          description={formatNumber(votes)}
          theme="grayBlack"
        />
        <Description
          title={t("shares.restrictiveConditions")}
          description={
            <div>
              {hasTrueConditions ? (
                <div>
                  {Object.entries(conditions).map(([key, value]) => {
                    const condition = conditionOptions.find(
                      (option) => option.value === key
                    );
                    return value ? (
                      <p
                        key={condition?.value}
                        className="tw-flex tw-items-center tw-gap-1"
                      >
                        {condition?.label}{" "}
                        <div className="tw-hidden md:tw-block">
                          <TooltipV2
                            content={condition?.description}
                            placement="right-end"
                          >
                            <InfoIcon />
                          </TooltipV2>
                        </div>
                      </p>
                    ) : null;
                  })}
                </div>
              ) : (
                <p>{t("shares.noConditions")}</p>
              )}
            </div>
          }
          theme="grayBlack"
        />
      </div>
    </Dialog>
  );
};

export { ShareBlockDialog };
