import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Entity } from "../../../types/models/entities";
import { rangeIsOverlapping } from "../../../utils/shares";
import { Button } from "../Button";
import { FormError, FormGroup, FormLabel } from "../FormGroup";
import { PlusIcon, TrashIcon } from "../icons";
import { Input } from "../Input";
import { Select } from "../Select";
import { MultiValue } from "./MultiValue";
import { Option } from "./Option";

type ShareRange = {
  start?: number;
  end?: number;
  block: {
    start: number;
    end: number;
  };
};

type ShareBlock = {
  start: number;
  end: number;
  type: string;
  entity: Omit<Entity, "contact">;
};

type ShareRangeError = {
  start?: { message?: string };
  end?: { message?: string };
};

type ShareRangeProps = {
  allShareBlocks: ShareBlock[];
  value: ShareRange[];
  fieldKey: string;
  onChange: (newValue: ShareRange[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  errors?: ShareRangeError[];
  isDisabled?: boolean;
};

const SelectRange = ({
  allShareBlocks,
  value,
  fieldKey,
  onChange,
  register,
  errors,
  isDisabled = false,
}: ShareRangeProps) => {
  const i18n = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const selectedBlocks = allShareBlocks.filter((b) =>
    value.find((r) => b.start === r.block.start && b.end === r.block.end)
  );

  return (
    <FormGroup>
      <FormLabel htmlFor={fieldKey}>{i18n.t("label.shareRanges")}</FormLabel>
      <Select
        isMulti
        id={fieldKey}
        name={fieldKey}
        components={{
          Option: (props) => <Option {...props} />,
          MultiValue: (props) => <MultiValue {...props} />,
        }}
        getOptionLabel={({ start, end, type, entity }) => {
          return `${start} ${end} ${type} ${entity.name}`;
        }}
        getOptionValue={(option) => option.start.toString()}
        options={allShareBlocks}
        value={selectedBlocks}
        onChange={(newValue = []) => {
          onChange([
            ...newValue.map((b) => ({
              start: b.start,
              end: b.end,
              block: { start: b.start, end: b.end },
            })),
          ]);
        }}
        isDisabled={isDisabled}
      />

      <button type="button" onClick={() => setIsExpanded((curr) => !curr)}>
        <div className="tw-flex tw-items-center tw-gap-2">
          {isExpanded ? <CaretUp size={20} /> : <CaretDown size={20} />}
          <span className="tw-text-sm tw-text-secondary">
            {i18n.t("selectRange.advanced")}
          </span>
        </div>
      </button>
      {isExpanded && (
        <div className="tw-flex tw-flex-col tw-gap-4">
          {selectedBlocks.map((b, i) => {
            const matchingRanges = value.filter(
              (r) => b.start === r.block.start && b.end === r.block.end
            );
            return (
              <div
                className="tw-flex tw-flex-col tw-gap-4 tw-rounded tw-border tw-p-4"
                key={b.start}
              >
                <h4 className="tw-text-base">{`${i18n.t("label.shareBlock")} ${
                  i + 1
                }: ${b.start}-${b.end}`}</h4>
                {matchingRanges.map((r, j) => {
                  return (
                    <div
                      className="tw-flex tw-gap-2"
                      key={`${r.start}-${r.end}`}
                    >
                      <FormGroup className="tw-flex-1">
                        <FormLabel htmlFor={`${fieldKey}.${j}.start`}>
                          {i18n.t("label.from")}
                        </FormLabel>
                        <Input
                          id={`${fieldKey}.${j}.start`}
                          {...register(`${fieldKey}.${j}.start`, {
                            valueAsNumber: true,
                            required: i18n.t("error.validation.required"),
                            min: {
                              value: b.start,
                              message: i18n.t("error.validation.range.min", {
                                min: b.start,
                              }),
                            },
                            max: {
                              value: b.end,
                              message: i18n.t("error.validation.range.max", {
                                max: b.end,
                              }),
                            },
                            validate: () => {
                              const isOverlapping = rangeIsOverlapping(
                                r,
                                value.filter((_, index) => index !== j)
                              );
                              if (isOverlapping) {
                                return i18n.t(
                                  "error.validation.range.overlaps"
                                );
                              }
                              return true;
                            },
                          })}
                          type="number"
                          min={b.start}
                          max={b.end}
                        />
                        <FormError>
                          {errors && errors[j]?.start?.message}
                        </FormError>
                      </FormGroup>
                      <FormGroup className="tw-flex-1">
                        <FormLabel htmlFor={`${fieldKey}.${j}.end`}>
                          {i18n.t("label.to")}
                        </FormLabel>
                        <Input
                          id={`${fieldKey}.${j}.end`}
                          {...register(`${fieldKey}.${j}.end`, {
                            valueAsNumber: true,
                            required: i18n.t("error.validation.required"),
                            min: {
                              value: b.start,
                              message: i18n.t("error.validation.range.min", {
                                min: b.start,
                              }),
                            },
                            max: {
                              value: b.end,
                              message: i18n.t("error.validation.range.max", {
                                max: b.end,
                              }),
                            },
                            validate: () => {
                              const isOverlapping = rangeIsOverlapping(
                                r,
                                value.filter((_, index) => index !== j)
                              );
                              if (isOverlapping) {
                                return i18n.t(
                                  "error.validation.range.overlaps"
                                );
                              }
                              return true;
                            },
                          })}
                          type="number"
                          min={b.start}
                          max={b.end}
                        />
                        <FormError>
                          {errors && errors[j]?.end?.message}
                        </FormError>
                      </FormGroup>
                      <Button
                        onClick={() =>
                          onChange(value.filter((_, index) => index !== j))
                        }
                        className="tw-mt-[28px]"
                      >
                        <TrashIcon />
                      </Button>
                    </div>
                  );
                })}
                <Button
                  prefix={<PlusIcon />}
                  onClick={() =>
                    onChange([
                      ...value,
                      { block: { start: b.start, end: b.end } },
                    ])
                  }
                >
                  {i18n.t("selectRange.addInterval")}
                </Button>
              </div>
            );
          })}
        </div>
      )}
    </FormGroup>
  );
};

export { SelectRange };
export type { ShareBlock, ShareRange, ShareRangeError };
